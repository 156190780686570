import { h } from "preact";
import { route } from "preact-router";
// // import style from "./login.css";
// import { signInWithGoogle } from "../../../Firebase";

const LandingPage = () => {
  const handleLoginClick = () => {
    route("/login");
  };
  return (
    <div className="h-full ">
      <div className="bg-[#232830]  text-white">
        {/* Navbar */}
        <nav className="flex items-center justify-between py-8 px-8">
          <a href="https://sentora.ai">
            <img
              src="../../assets/logo.png"
              alt="Sentora Users"
              className="w-36"
            />
          </a>

          <div className="flex items-center space-x-4 md:space-x-8">
            <button
              onClick={handleLoginClick}
              className="bg-none hover:bg-blue-800 border-2 border-white shadow-gray-700 shadow-2xl text-white rounded-full py-2 px-4 md:py-3 md:px-6"
            >
              Login
            </button>
          </div>
        </nav>
        {/* Hero Section */}
        <section className="flex flex-col lg:flex-row items-center justify-center py-16 px-8">
          <div className="lg:w-1/2 md:ml-8">
            <h1 className="text-4xl  font-thin mb-4">Sentora</h1>
            <h2 className="text-2xl font-bold mb-6">Document Your Learning</h2>
            <p className="text-lg font-extralight mr-0 md:mr-32 mb-6">
              Effortlessly condense long videos into short summaries. Our
              AI-powered website saves you time by delivering concise
              information, making understanding easier.
            </p>
            <a href="https://forms.gle/Zizmm31omBkK6CCbA">
              <button className="bg-none hover:bg-blue-800 border-2 border-white  shadow-gray-700 shadow-2xl text-white rounded-full py-3 px-6">
                Join Now
              </button>
            </a>
          </div>
          <div className="lg:w-1/2 mt-8 lg:mt-0">
            <img
              src="../../assets/22.png"
              alt="Sentora Users"
              // width="156px"
              className=" w-128 "
            />
            {/* <div className="md:w-[36rem] md:h-[36rem] w-64 h-64 mx-auto bg-indigo-800 rounded-full"></div> */}
          </div>
        </section>

        {/* Footer */}
        <footer className=" py-12 bg-[#191c21] px-auto center mx-auto text-center   ">
          <p className="text-lg text-gray-200 ">
            © 2023 <span className="text-blue-700  font-bold">Sentora</span>{" "}
            <br></br>
            <span className="font-bold"> All rights reserved.</span>
          </p>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;
