import Helmet from "preact-helmet";
import { Router, Route } from "preact-router";
import { useContext } from "preact/hooks";

import ReactGA from "react-ga";

import Header from "./header";
import Login from "../routes/login/login";

import Home from "../routes/home";
import Profile from "../routes/profile";
import Dashboard from "../routes/dashboard/dashboard";
import Summary from "../routes/summary/summary";
import Base from "../routes/base/base";

import { useEffect, useState } from "preact/hooks";
import LandingPage from "../routes/landing/landing";
import { UrlContext } from "../routes/context/UrlContext";
import { ThemeContext } from "../routes/context/ThemeContext";
import ThemeProvider from "../components/ThemeProvider";

import User from "../routes/user/user";
import Homee from "../routes/homw/homee";
import Error from "../routes/404/404";

const SetTitle = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return null;
};

const App = () => {
  const [videoUrl, setVideoUrl] = useState("");
  const [rl, setRl] = useState(false);
  const { theme } = useContext(ThemeContext);

  ReactGA.initialize("UA-267358156-1");
  const handleRouteChange = (e) => {
    ReactGA.pageview(e.url);
  };

  return (
    <ThemeProvider>
      <div id="app">
        <div className={theme}>
          <UrlContext.Provider value={{ rl, setRl }}>
            <SetTitle title="Sentora" />

            <main>
              <Router onChange={handleRouteChange}>
                <Base path="/base" />
                <Login path="/login" />
                <User
                  path="/user"
                  videoUrl={videoUrl}
                  setVideoUrl={setVideoUrl}
                />
                <LandingPage path="/" />
                <Dashboard
                  path="/dashboard"
                  videoUrl={videoUrl}
                  setVideoUrl={setVideoUrl}
                />

                <Summary
                  path="/summary/:encodedUrl"
                  videoUrl={videoUrl}
                  setVideoUrl={setVideoUrl}
                />

                <Profile path="/profile/" user="me" />
                <Profile path="/profile/:user" />
                {/* <Homee path="/" /> */}
                <Error default />
              </Router>
            </main>
          </UrlContext.Provider>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
