import "./base.css";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "preact-router";
// Import Dashboard component

import Dashboard from "../dashboard/dashboard";

import { useState, useRef } from "preact/hooks";
import style from "./base.css";
import { route } from "preact-router";

// const Base = ({ videoUrl, setVideoUrl }) => {
const Base = () => {
  const inputRef = useRef();
  // const profilepicture = localStorage.getItem("profilePic");
  const [showMenu, setShowMenu] = useState(false);
  const [url, setUrl] = useState("");
  const [encode, setEncode] = useState("");
  const [iurl, setIurl] = useState("");
  return (
    <div>
      <div className={style.background}>
        <div className=" flex  justify-center mx-auto pt-4 md:pt-6 ">
          <div>
            {" "}
            <Link href="/dashboard">
              <img
                src="../../assets/logo.png"
                alt="Sentora Logo"
                // width="156px"
                className=" w-44 ml-8"
              />
            </Link>
          </div>
          {/* <div>
            <img
              class="h-12 w-12 mr-4 rounded-full cursor-pointer"
              src={profilepicture}
              alt=""
              onClick={() => setShowMenu(!showMenu)}
            />
            {showMenu && (
              <div class="absolute top-20 right-4  w-36 z-10 bg-white text-black p-2 rounded-lg shadow-lg">
                <ul className=" font-medium p-3">
                  <li>
                    <a href="/user" className=" hover:text-indigo-600">
                      Profile
                    </a>
                  </li>
                  <li>
                    <a href="/logout" className=" hover:text-indigo-600">
                      Sign Out
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div> */}
        </div>
        <div className="flex flex-col  text-center mt-4 md:mt-16 ">
          <div className="heading1 text-[48px] md:text-[64px]">
            <span className="text-[#DFF6FF]">This is</span>
            <span className="text-yellow-400"> Sentora</span>
          </div>
          <div className="md:text-[20px] text-[16px] px-4 md:px-0  text-[#DFF6FF]">
            Sentora helps you save time by summarizing YouTube videos!
          </div>
          <div className="md:text-[20px] text-[16px] px-4 md:px-0 text-[#DFF6FF]">
            With Sentora, you can get a summary of a long video in just a few
            minutes. Here's how
          </div>
          <div className="visible md:hidden">
            {" "}
            <div className={style.input}>
              <form>
                <div className="relative m-2 rounded-md shadow-sm flex">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FontAwesomeIcon icon={faLink} className="text-white" />
                  </div>

                  <input
                    type="text"
                    placeholder="Link to YouTube"
                    className="w-full bg-[#131524] text-white rounded-md py-2 mx-2 pl-7 pr-20"
                    onChange={(e) => setIurl(e.target.value)} // Add this line
                    // onChange={(e) => setUrl(e.target.value)}
                  />

                  <button
                    className={style.button}
                    onClick={() => {
                      setVideoUrl(iurl);
                      console.log(videoUrl);
                      route("/login");
                    }}
                    //  onClick={handleSubmit}
                  >
                    <a href="/login">→</a>
                  </button>

                  {/* <button className={style.button}></button> */}
                </div>
              </form>
            </div>
          </div>
          {/* <div className="mt-20 mb-8 text-white">
            <div className="flex justify-around  ">
              <div className="flex flex-col ml-16 px-8">
                <div className=" flex justify-center">
                  <img
                    src="../../assets/i1.png"
                    alt="Preact Logo"
                    height="128px"
                    width="128px"
                  />
                </div>
                <div className="text-[20px] px-16">
                  Copy the link to the YouTube video you want to summarize.
                </div>
              </div>
              <div className="flex flex-col px-8">
                <div className=" flex justify-center">
                  <img
                    src="../../assets/i2.png"
                    alt="Preact l"
                    height="128px"
                    width="128px"
                  />
                </div>
                <div className="text-[20px] px-16">
                  Paste the link into the text box below, & hit summarize
                </div>
              </div>
              <div className="flex flex-col px-8 mr-16">
                <div className=" flex justify-center">
                  <img
                    src="../../assets/i3.png"
                    alt="Preact Logo"
                    height="128px"
                    width="128px"
                  />
                </div>
                <div className="text-[20px] px-8 ">
                  In a few minutes, the summary will be generated!
                </div>
              </div>
            </div>
          </div> */}
          <div className="mt-20 mb-8 text-white">
            <div className="flex-col flex md:flex-row justify-center md:space-x-48">
              <div className="flex flex-col items-center md:items-left">
                <img
                  src="../../assets/i1.png"
                  alt="Preact Logo"
                  height="128px"
                  width="128px"
                  className="mb-[-2rem]"
                />
                <div className="text-[20px] mt-4 text-left w-[264px]">
                  Copy the link to the YouTube video you want to summarize.
                </div>
              </div>
              <div className="flex flex-col items-center md:items-left">
                <img
                  src="../../assets/i2.png"
                  alt="Preact l"
                  height="128px"
                  width="128px"
                  className="mb-[-2rem]"
                />
                <div className="text-[20px] mt-4 text-left w-[264px]">
                  Paste the link into the text box below, & hit summarize
                </div>
              </div>
              <div className="flex flex-col items-center md:items-left">
                <img
                  src="../../assets/i3.png"
                  alt="Preact Logo"
                  height="128px"
                  width="128px"
                  className="mb-[-2rem]"
                />
                <div className="text-[20px] mt-4 text-left w-[264px]">
                  In a few minutes, the summary will be generated!
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="mx-4 sm:mx-96">{/* search begins */}</div>
          <div className="hidden md:block">
            <div className={style.input}>
              <form>
                <div className="relative m-2 rounded-md shadow-sm flex">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FontAwesomeIcon icon={faLink} className="text-white" />
                  </div>

                  <input
                    type="text"
                    placeholder="Paste link to YouTube video"
                    className="w-full bg-[#131524] text-white rounded-md py-2 mx-2 pl-7 pr-20"
                    onChange={(e) => setIurl(e.target.value)} // Add this line
                    // onChange={(e) => setUrl(e.target.value)}
                  />

                  <button
                    className={style.button}
                    onClick={() => {
                      setVideoUrl(iurl);
                      console.log(videoUrl);
                      route("/login");
                    }}
                    //  onClick={handleSubmit}
                  >
                    {" "}
                    <Link href="/login">Summarize </Link>
                  </button>

                  {/* <button className={style.button}></button> */}
                </div>
              </form>
            </div>

            {/* search ends */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Base;
