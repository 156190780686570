import { route, Link } from "preact-router";
import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "preact/hooks";
import style from "./summary.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/footer/footer";
import { UrlContext } from "../context/UrlContext";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

function getVideoId(url) {
  const regex =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([\w-]{10,}).*$/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

function Summary(props) {
  const intervalId = useRef(null);

  const authToken = localStorage.getItem("idToken");
  const profilepicture = localStorage.getItem("profilePic");

  const { setRl } = useContext(UrlContext);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const [url, setUrl] = useState("");
  const [chatVisible, setChatVisible] = useState(false);
  const [chatHeight, setChatHeight] = useState("auto");
  const [isTyping, setIsTyping] = useState(false);
  const [summaryData, setSummaryData] = useState("");
  const [videourl, setVideoUrl] = useState("");
  const [iurl, setIurl] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  useEffect(() => {
    const decodedUrl = decodeURIComponent(props.encodedUrl);
    setVideoUrl(decodedUrl);
    console.log("@4");
  }, [props.encodedUrl]);
  const fetchSummaryData = useCallback(
    async (url) => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ videourl: url, authtoken: authToken }),
      };

      try {
        const response = await fetch(
          // "https://ytsummarizer-production-5ff2.up.railway.app/summary",
          "https://d10kmmydm845tw.cloudfront.net/summary",
          requestOptions
        );

        if (!response.ok) {
          const errorText = await response.text();
          if (
            // response.status === 500 &&
            errorText.message === "try again later"
          ) {
            alert("Summary can't be made");
            route("/dashboard");
            return;
          }
          throw new Error(
            `Server responded with ${response.status}: ${errorData.message}`
          );
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching summary:", error);
      }
    },
    [authToken]
  );

  const handleSignOut = () => {
    // Clear authentication tokens or user data, e.g., localStorage or cookies
    localStorage.removeItem("authToken");
    // Redirect to the login page or any other appropriate page
    route("/login");
  };
  useEffect(() => {
    if (videourl) {
      fetchSummaryData(videourl);
    }
  }, [videourl, fetchSummaryData]);

  const lines = summaryData
    ? summaryData.videosummary.split("\n").map((line) => line.trim())
    : [];

  const sections = lines.reduce((acc, line) => {
    if (line.includes(":")) {
      acc.push({ subheading: line, bulletPoints: [] });
    } else if (line.startsWith("-") && acc.length > 0) {
      acc[acc.length - 1].bulletPoints.push(line.slice(1).trim());
    }
    return acc;
  }, []);

  console.log(videourl);

  console.log(summaryData);
  console.log(videourl);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://d10kmmydm845tw.cloudfront.net/summarize",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            videoUrl: iurl,
            authtoken: authToken,
          }),
        }
      );
      console.log("2");
      alert("Your Summary will be shown on the Dashboard");
      const data = await response.json();

      route("/dashboard");
    } catch (error) {
      console.error(error);
      console.error("Error submitting summary:", error);
    }
  }

  const [isLoadingSummary, setLoadingSummary] = useState(true);
  const [overloaded, setOverloaded] = useState(false);
  const [summaryUnavailable, setSummaryUnavailable] = useState(false);

  const fetchSummaryDataPeriodically = async () => {
    setLoading(true);
    const fetchedData = await fetchSummaryData(videourl);

    if (fetchedData.videosummary === "we are working on it,we promise\n$") {
      console.log("summary is unavailable");
      setSummaryUnavailable(true);
      console.log(summaryUnavailable);
      setLoading(false);
      setLoadingSummary(false);
      clearInterval(intervalId.current); // set summaryUnavailable to true
    } else if (
      fetchedData &&
      fetchedData.videosummary.endsWith("sorry we are a bit overloaded\n$")
    ) {
      console.log("Summary is being overloaded");
      // Add the logic to display your division here
      // ...

      // setSummaryUnavailable(true);
      console.log(summaryUnavailable);
      setLoading(false);
      setLoadingSummary(false);

      setOverloaded(true);
      setSummaryData(fetchedData);
      clearInterval(intervalId.current); // clear the interval if the summary is complete
    } else if (fetchedData && fetchedData.videosummary.includes("$")) {
      // Remove the '$' string from the summary before setting the state
      fetchedData.videosummary = fetchedData.videosummary.replace("$", "");
      setSummaryData(fetchedData);
      setLoading(false);
      setLoadingSummary(false); // set loadingSummary to false when $ is replaced
      clearInterval(intervalId.current); // clear the interval if the summary is complete
    } else {
      setSummaryData(fetchedData);
    }
  };

  // const fetchSummaryDataPeriodically = async () => {
  //   setLoading(true);
  //   const fetchedData = await fetchSummaryData(videourl);

  //   if (fetchedData.videosummary === "we are working on it,we promise\n$") {
  //     console.log("summary is unavailable");
  //     setSummaryUnavailable(true);
  //     console.log(summaryUnavailable);
  //     setLoading(false);
  //     setLoadingSummary(false);
  //     clearInterval(intervalId.current); // set summaryUnavailable to true
  //   } else if (
  //     fetchedData &&
  //     fetchedData.videosummary.endsWith("sorry we are a bit overloaded\n$")
  //   ) {
  //     console.log("Summary is being overloaded");
  //     // Add the logic to display your division here
  //     // ...

  //     // setSummaryUnavailable(true);
  //     console.log(summaryUnavailable);
  //     setLoading(false);
  //     setLoadingSummary(false);

  //     setOverloaded(true);
  //     setSummaryData(fetchedData);
  //     clearInterval(intervalId.current); // clear the interval if the summary is complete
  //   } else if (fetchedData && fetchedData.videosummary.includes("$")) {
  //     // Remove the '$' string from the summary before setting the state
  //     fetchedData.videosummary = fetchedData.videosummary.replace("$", "");
  //     setSummaryData(fetchedData);
  //     setLoading(false);
  //     setLoadingSummary(false); // set loadingSummary to false when $ is replaced
  //     clearInterval(intervalId.current); // clear the interval if the summary is complete
  //   } else {
  //     setSummaryData(fetchedData);
  //   }
  // };

  // Update the useEffect hook
  useEffect(() => {
    if (videourl) {
      fetchSummaryDataPeriodically(); // Fetch data initially
      const id = setInterval(fetchSummaryDataPeriodically, 500); // Fetch data every 5 seconds
      intervalId.current = id;

      return () => {
        clearInterval(intervalId.current); // Clear the interval when the component is unmounted
      };
    }
  }, [videourl, fetchSummaryData]);

  const [warningMessage, setWarningMessage] = useState(null);

  // Modify your sendMessage function
  const sendMessage = async () => {
    if (input.trim() === "") return;

    if (messages.length % 2 !== 0 && messages.length !== 0) {
      setWarningMessage(
        "Please wait for the server to respond before sending another message."
      );
      setInput("");
      return;
    }

    // Add the user's message as a string to the messages array
    const updatedMessages = [...messages, input.trim()];
    setMessages(updatedMessages);

    setInput("");
    setIsTyping(true); // Show the typing animation

    // Prepare request options
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        summary: summaryData.videosummary,
        authToken: authToken,
        list: updatedMessages, // Send the entire messages array as 'list'
      }),
    };

    try {
      // Send the message to the server and receive a response
      const response = await fetch(
        // "https://ytsummarizer-production-5ff2.up.railway.app/chat",
        "https://d10kmmydm845tw.cloudfront.net/chat",
        requestOptions
      );

      if (!response.ok) {
        if (
          response.status === 429 ||
          response.statusText === "we are currently overloaded try again"
        ) {
          const botErrorMessage = "Sorry, error occurred";
          setMessages((prevMessages) => [...prevMessages, botErrorMessage]);
        }
        throw new Error("Server responded with a status other than 200");
      }

      const responseData = await response.text();
      let botMessage;

      try {
        botMessage = JSON.parse(responseData).message;
      } catch (error) {
        botMessage = responseData;
      }

      // Add the bot's message as a string to the messages array
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      scrollToBottom();
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setWarningMessage(null);
      setIsTyping(false); // Hide the typing animation
      if (!chatVisible) {
        handleChatToggle();
      }
    }
  };

  const message1 = () => {
    const userMessage = "what are the insights of this video";

    // Add the user's message to the messages array
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);

    setIsTyping(true); // Show the typing animation

    // Prepare request options
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        summary: summaryData.videosummary,
        authToken: authToken,
        list: updatedMessages, // Send the entire messages array as 'list'
      }),
    };

    // Send the message to the server and receive a response
    fetch(
      // "https://ytsummarizer-production-5ff2.up.railway.app/chat",
      "https://d10kmmydm845tw.cloudfront.net/chat",
      requestOptions
    )
      .then((response) => response.text())
      .then((responseData) => {
        let botMessage;

        try {
          botMessage = JSON.parse(responseData).message;
        } catch (error) {
          botMessage = responseData;
        }

        // Add the bot's message as a string to the messages array
        setMessages((prevMessages) => [...prevMessages, botMessage]);
        scrollToBottom();
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      })
      .finally(() => {
        setIsTyping(false);
        setWarningMessage(null); // Hide the typing animation
        if (!chatVisible) {
          handleChatToggle();
        }
      });
  };

  const message2 = () => {
    const userMessage = "Who is in this video?";

    // Add the user's message to the messages array
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);

    setIsTyping(true); // Show the typing animation

    // Prepare request options
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        summary: summaryData.videosummary,
        authToken: authToken,
        list: updatedMessages, // Send the entire messages array as 'list'
      }),
    };

    // Send the message to the server and receive a response
    fetch("https://d10kmmydm845tw.cloudfront.net/chat", requestOptions)
      .then((response) => response.text())
      .then((responseData) => {
        let botMessage;

        try {
          botMessage = JSON.parse(responseData).message;
        } catch (error) {
          botMessage = responseData;
        }

        // Add the bot's message as a string to the messages array
        setMessages((prevMessages) => [...prevMessages, botMessage]);
        scrollToBottom();
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      })
      .finally(() => {
        setIsTyping(false);
        setWarningMessage(null); // Hide the typing animation
        if (!chatVisible) {
          handleChatToggle();
        }
      });
  };

  const message3 = () => {
    const userMessage = "What is the main message of this video?";

    // Add the user's message to the messages array
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);

    setIsTyping(true); // Show the typing animation

    // Prepare request options
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        summary: summaryData.videosummary,
        authToken: authToken,
        list: updatedMessages, // Send the entire messages array as 'list'
      }),
    };

    // Send the message to the server and receive a response
    fetch(
      // "https://ytsummarizer-production-5ff2.up.railway.app/chat",
      "https://d10kmmydm845tw.cloudfront.net/chat",
      requestOptions
    )
      .then((response) => response.text())
      .then((responseData) => {
        let botMessage;

        try {
          botMessage = JSON.parse(responseData).message;
        } catch (error) {
          botMessage = responseData;
        }

        // Add the bot's message as a string to the messages array
        setMessages((prevMessages) => [...prevMessages, botMessage]);
        scrollToBottom();
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      })
      .finally(() => {
        setIsTyping(false);
        setWarningMessage(null); // Hide the typing animation
        if (!chatVisible) {
          handleChatToggle();
        }
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const targetRef = useRef(null);

  const scrollToDiv = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxHeight, setMaxHeight] = useState("82%");

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    if (scrollPosition > 0) {
      setMaxHeight("99%");
    } else {
      setMaxHeight("82%");
    }
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const downloadPdf = () => {
    // Define a document
    var docDefinition = {
      content: [
        { text: "Sentora.ai, Document your learning", fontSize: 22 },
        { text: summaryData.videosummary },
      ],
    };

    // Create and download the PDF
    pdfMake.createPdf(docDefinition).download("download.pdf");
  };

  // const [jsPDF, setJsPDF] = useState(null);

  // useEffect(() => {
  //   import("jspdf").then((module) => {
  //     setJsPDF(() => module.jsPDF);
  //   });
  // }, []);

  // const downloadPdf = async () => {
  //   if (jsPDF && summaryData && summaryData.videosummary) {
  //     const doc = new jsPDF();

  //     const margin = 20; // Define margin size
  //     const maxWidth = doc.internal.pageSize.getWidth() - 2 * margin; // Maximum width of content

  //     // Set the font and its size
  //     doc.setFont("Roboto"); // Make sure to load this font first

  //     // Add the header
  //     doc.setFontSize(22); // Increase font size for header
  //     doc.setTextColor(0, 0, 255); // Set the color to blue
  //     doc.text("Sentora.ai, Document your learning", margin, margin);

  //     // Add the video summary text
  //     doc.setTextColor(0, 0, 0); // Set the color to black
  //     doc.setFontSize(12); // Set the font size for the summary

  //     const summaryLines = doc.splitTextToSize(
  //       summaryData.videosummary,
  //       maxWidth
  //     ); // Split the summary into lines
  //     let y = 28; // Calculate the y coordinate for the first line

  //     summaryLines.forEach((line) => {
  //       if (y > doc.internal.pageSize.getHeight() - margin) {
  //         doc.addPage();
  //         y = margin;
  //       }
  //       doc.text(line, margin, y);
  //       y += 5;
  //     });

  //     // The rest of your PDF generation code goes here...

  //     // Add the footer to each page
  //     const pageCount = doc.getNumberOfPages();
  //     for (let i = 1; i <= pageCount; i++) {
  //       doc.setPage(i);
  //       doc.text(`Page ${i}`, margin, doc.internal.pageSize.getHeight() - 16);
  //     }

  //     doc.save("download.pdf");
  //   } else {
  //     console.log("jsPDF or summaryData is not defined");
  //   }
  // };
  return (
    // <div className={style.background}>
    <div>
      <div className=" flex flex-col">
        <div className={style.header}>
          <div className=" flex  justify-between mx-8 py-4 md:pb-[3rem] md:pt-8">
            <div className=" font-medium  h-4 w-auto  text-slate-50">
              <Link href="/dashboard">
                <img
                  src="../../assets/logo.png"
                  alt="Sentora Logo"
                  // width="156px"
                  className=" w-44 ml-8 hidden md:block"
                />
                <img
                  src="../../assets/logo3.png"
                  alt="Sentora Logo"
                  // width="156px"

                  className=" w-10 mr-4  visible sm:hidden"
                />
              </Link>
            </div>
            <div>
              <div className="mx-4 sm:mx-96">{/* search begins */}</div>
              <div className=" hidden md:block">
                <div className={style.input}>
                  <div className="relative m-1.5 pl-2 rounded-md shadow-sm flex">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FontAwesomeIcon icon={faLink} className="text-white" />
                    </div>

                    <input
                      type="text"
                      placeholder="Paste link to YouTube video here "
                      className="w-full bg-[#131524] text-white rounded-md sm:py-2 hidden md:block py-1 mr-1 pl-10 pr-20"
                      onChange={(e) => setIurl(e.target.value)} // Add this line
                    />
                    <input
                      type="text"
                      placeholder="Paste link here "
                      className="w-full bg-[#131524] text-white rounded-md  block md:hidden py-1 mr-0 pl-10 "
                      onChange={(e) => setIurl(e.target.value)} // Add this line
                    />

                    <button
                      className={style.button}
                      onClick={() => {
                        setRl(iurl);
                        route("/dashboard");
                      }}
                    >
                      Summarize
                    </button>
                    <button
                      className={style.button2}
                      onClick={() => {
                        setRl(iurl);
                        route("/dashboard");
                      }}
                    >
                      →
                    </button>
                  </div>
                </div>
              </div>
              {/* search ends */}
            </div>

            <div className="text-white ">
              {" "}
              <div class="relative">
                <img
                  class="h-12 w-12 mr-4 rounded-full cursor-pointer"
                  src={profilepicture}
                  alt=""
                  onClick={() => setShowMenu(!showMenu)}
                />
                {showMenu && (
                  <div class="absolute top-14 right-4  w-36 z-[1000] bg-white text-black p-2 rounded-lg shadow-lg">
                    <ul className=" font-medium p-3">
                      <li>
                        <a href="/user" className=" hover:text-indigo-600 ">
                          Profile
                        </a>
                      </li>
                      <li>
                        <button
                          onClick={handleSignOut}
                          className=" hover:text-indigo-600 "
                        >
                          Sign Out
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* {!loading && summaryData ? ( */}
        <div>
          <div className="bg-white flex-grow">
            <div className="flex flex-col md:flex-row">
              {/* Video player and summary section */}
              {/* <div classname="max-w-3xl mx-auto px-2 md:px-0 flex-grow"> */}
              <div className="max-w-3xl mx-auto px-2  flex-grow">
                <div className="sticky top-0 bg-white z-10 border-gray-200 border-b-[1px]">
                  <div className=" mx-auto py-2  sm:py-4  flex-grow-4 items-center">
                    <div className="flex items-center">
                      <h2 className="text-1xl font-bold text-gray-900 tracking-tight sm:text-3xl flex-grow">
                        {summaryData.title}
                        {summaryUnavailable && (
                          <div>
                            <h2 className="text-2xl font-bold text-gray-900 tracking-tight sm:text-3xl flex-grow">
                              This video is Unavailable
                            </h2>
                          </div>
                        )}
                      </h2>

                      <button
                        className="text-gray-700 hover:text-gray-800"
                        onClick={() => {
                          if (navigator.clipboard) {
                            navigator.clipboard
                              .writeText(summaryData.videosummary)
                              .then(() => {
                                alert("Summary copied to clipboard!");
                              })
                              .catch((err) => {
                                console.error("Failed to copy text: ", err);
                              });
                          } else {
                            const textarea = document.createElement("textarea");
                            textarea.value = summaryData.videosummary;
                            document.body.appendChild(textarea);
                            textarea.select();
                            try {
                              document.execCommand("copy");
                              alert("Summary copied to clipboard!");
                            } catch (err) {
                              console.error("Failed to copy text: ", err);
                            } finally {
                              document.body.removeChild(textarea);
                            }
                          }
                        }}
                      >
                        <span className="">
                          {" "}
                          <img
                            src="../../assets/copy.png"
                            alt="Copy"
                            // width="156px"
                            className="  w-12"
                          />
                        </span>
                      </button>

                      <span>
                        {" "}
                        <button onClick={downloadPdf}>
                          <span className="">
                            <img
                              src="../../assets/downloadlogo.png"
                              alt="Download"
                              // width="156px"
                              className="  w-[2.6rem] "
                            />
                          </span>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center w-full bg-white pt-6">
                  <div className="relative w-full py-4 sm:py-0 md:w-5/5 lg:w-4/4">
                    <div
                      className="relative pt-9 pb-16 sm:pb-56 lg:pb-32"
                      style={{ paddingTop: "50%" }}
                    >
                      <iframe
                        className="absolute inset-0 w-full h-full rounded-[0.5rem]  "
                        src={`https://www.youtube.com/embed/${getVideoId(
                          videourl
                        )}`}
                        title="title"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    onClick={scrollToDiv}
                    class="fixed bottom-5  z-50 left-1/2 transform -translate-x-1/2 bg-[#051367] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full md:hidden lg:hidden"
                  >
                    Chat
                  </button>

                  {/* <button
                    onClick={scrollToDiv}
                    class="bg-[#051367] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded  md:hidden lg:hidden"
                  >
                    Chat
                  </button> */}
                </div>

                {/* <div className="text-center">
                <h3 className="text-3xl font-medium mt-8 text-gray-900 tracking-tight">
                  Summary
                </h3>
              </div> */}

                <div className="mt-8 text-md md:text-xl text-gray-800">
                  {summaryUnavailable && (
                    <div>
                      <p className="text-xl text-center">
                        Video Trouble? Our Team is Here! Click Here to Help Us
                        Fix It. Please note, we can't generate summaries for
                        videos without captions.{" "}
                      </p>
                      <div className="flex justify-center">
                        <a href="https://forms.gle/crtzixr9kChNJ5488">
                          <button className="bg-blue-600 text-white rounded-full m-4 py-2 px-4">
                            Submit Error
                          </button>
                        </a>
                      </div>
                    </div>
                  )}
                  {summaryData.videosummary === "summary being prepared" ? (
                    <div className="flex ">
                      Summary is being prepared...
                      {/* <div
                        class="inline-block text-[#2D31FA]   h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      ></div> */}
                    </div>
                  ) : (
                    <div className="relative">
                      <div className="absolute top-0 left-0 w-full h-full bg-white z-10">
                        {sections.map((section, index) => {
                          const { subheading, bulletPoints } = section;
                          return (
                            <div key={index} className="mb-4">
                              <div className="font-bold pl-4 mt-4 mb-2 clip-text">
                                {index + 1}. {subheading}
                              </div>
                              <ul className="list-disc pl-8">
                                {bulletPoints.map((point, i) => (
                                  <li
                                    key={i}
                                    className="mb-2 ml-[1rem] clip-text"
                                  >
                                    {point}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          );
                        })}
                      </div>

                      <div>
                        {sections.map((section, index) => {
                          const { subheading, bulletPoints } = section;
                          return (
                            <div key={index} className="mb-4">
                              <div className="font-bold  pl-4 mt-4 mb-2">
                                {index + 1}. {subheading}
                              </div>
                              <ul className="list-disc pl-8">
                                {bulletPoints.map((point, i) => (
                                  <li key={i} className="mb-2 ml-[1rem]">
                                    {point}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                {overloaded && (
                  <div>
                    <p className="text-xl text-blue-700 mt-4 text-center">
                      Our servers are overloaded right now so we can't provide
                      you with the whole summary. Our team is working on it.
                    </p>
                    <div className="flex justify-center">
                      {/* <a href="https://forms.gle/UkvrYBnRzYzcBPm88"> */}
                      <a href="https://forms.gle/crtzixr9kChNJ5488">
                        <button className="bg-blue-600 text-white rounded-full m-4 py-2 px-4">
                          Submit Response
                        </button>
                      </a>
                    </div>
                  </div>
                )}

                {isLoadingSummary && (
                  <div className="flex justify-start space-x-2 my-4 py-4 mx-6">
                    <div className="w-2 h-2 bg-gray-800 rounded-full animate-bounce delay-150"></div>
                    <div className="w-2 h-2 bg-gray-800 rounded-full animate-bounce delay-300"></div>
                    <div className="w-2 h-2 bg-gray-800 rounded-full animate-bounce delay-450"></div>
                  </div>
                )}
              </div>

              {/* Chat component */}
              {/* <div id="target" ref={targetRef}> */}

              <div
                className="w-full sm:w-1/3  sticky flex flex-col  "
                id="target"
                ref={targetRef}
              >
                <div className="sticky top-0">
                  {" "}
                  {/* <div className="chat-container h-screen border-4 md:border-0 rounded-md md:rounded-none my-4 md:my-0 ">
                    <h2 class="bg-[#051367]  text-white border-4  text-center font-bold  text-xl py-2 px-auto rounded-md  md:hidden lg:hidden">
                      Chat
                    </h2>
                    <div className="max-w-3xl  h-full flex flex-col ">
                      <div
                        className="bg-white h-full  border-l-2 overflow-y-scroll transition-all duration-500"
                        style={{
                          maxHeight: "calc(82% - 3rem)",
                        }}
                      > */}
                  <div className="chat-container h-screen border-4 md:border-0 rounded-md md:rounded-none my-4 md:my-0 ">
                    <h2 class="bg-[#051367]  text-white border-4  text-center font-bold  text-xl py-2 px-auto rounded-md  md:hidden lg:hidden">
                      Chat
                    </h2>
                    <div className="max-w-3xl  h-full flex flex-col ">
                      <div
                        className="bg-white h-full  border-l-2 overflow-y-scroll transition-all duration-500"
                        style={{
                          maxHeight: `calc(${maxHeight} - 3rem)`,
                        }}
                      >
                        {/* ... */}
                        {/* Messages */}
                        {messages.length === 0 ? (
                          <div className="text-center text-gray-500 p-4">
                            <img
                              src="../../assets/yt-q.png"
                              alt="YT image Logo"
                              // width="156px"
                              className=" w-80 mx-auto"
                            />
                            <p className=" text-xl font-medium p-2">
                              <span> Still have doubts?</span>{" "}
                              <span className=" text-indigo-600">
                                Ask the video!
                              </span>{" "}
                            </p>
                            <button
                              className="w-full cursor-pointer"
                              onClick={message1}
                            >
                              <p class=" text-lg text-indigo-600 border-[1px] m-2 rounded-sm border-[#CED2FF]  p-2 ">
                                What are the key insights?
                              </p>
                            </button>
                            <button
                              className="w-full cursor-pointer"
                              onClick={message2}
                            >
                              <p class=" text-lg text-indigo-600 border-[1px] m-2 rounded-sm border-[#CED2FF]  p-2">
                                Who is in this video?
                              </p>
                            </button>
                            <button
                              className="w-full cursor-pointer"
                              onClick={message1}
                            >
                              <p class=" text-lg text-indigo-600 border-[1px] m-2 rounded-sm border-[#CED2FF]  p-2">
                                What is the main message?
                              </p>
                            </button>
                          </div>
                        ) : (
                          messages.map((message, index) => (
                            <div
                              key={index}
                              className={`${
                                index % 2 === 0
                                  ? "text-right rounded-3xl"
                                  : "text-left rounded-3xl border-red-500"
                              } mb-2 mt-1 mx-2`}
                            >
                              <span
                                className={`${
                                  index % 2 === 0
                                    ? "bg-[#2D31FA] text-white text-left rounded-3xl rounded-br-none "
                                    : "bg-white rounded-3xl border-2   text-gray-800 border-[#2D31FA]   rounded-bl-none"
                                } text-black inline-block px-3 py-2 mx-2`}
                              >
                                {message}
                              </span>
                            </div>
                          ))
                        )}

                        {warningMessage && (
                          <div className="text-left mb-2 mt-1 mx-2">
                            <span className="bg-red-600 text-white  rounded-3xl  inline-block px-3 py-2 mx-2">
                              {warningMessage}
                            </span>
                          </div>
                        )}
                        <div>
                          {isTyping && (
                            <span className="text-gray-900 mx-2 text-5xl inline-block animate-pulse">
                              ...
                            </span>
                          )}
                        </div>
                        <div ref={messagesEndRef}></div>
                      </div>
                      <div className=" flex border-2">
                        <input
                          className="w-full px-4 m-2 py-2"
                          type="text"
                          value={input}
                          onChange={(e) => setInput(e.target.value)}
                          onKeyPress={handleKeyPress}
                          placeholder="Type your message"
                        />
                        <button
                          className={`m-2 py-2 px-4 font-bold text-white rounded-md ${
                            warningMessage
                              ? "bg-gray-400 cursor-not-allowed"
                              : "bg-[#051367]"
                          }`}
                          onClick={warningMessage ? null : sendMessage}
                          disabled={warningMessage}
                        >
                          →
                        </button>

                        {/* <button
                          className=" m-2 py-2 px-4 font-bold text-white rounded-md bg-[#051367]"
                          onClick={sendMessage}
                        >
                          →
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg  w-2/3 md:w-1/3 h-40vh p-6 shadow-lg">
            <div className="flex justify-end"></div>

            <div className="mt-2">
              <p className="text-xl text-center">
                The summary is taking longer than expected
              </p>
            </div>
            <div className="mt-4 flex justify-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setIsModalVisible(false)}
              >
                Stay
              </button>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => route("/dashboard")}
              >
                Dashboard
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white">
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Summary;

// import { route, Link } from "preact-router";
// import {
//   useEffect,
//   useState,
//   useCallback,
//   useRef,
//   useContext,
// } from "preact/hooks";
// import style from "./summary.css";

// import { jsPDF } from "jspdf";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLink } from "@fortawesome/free-solid-svg-icons";
// import Footer from "../../components/footer/footer";
// import { UrlContext } from "../context/UrlContext";
// function getVideoId(url) {
//   const regex =
//     /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([\w-]{10,}).*$/;
//   const match = url.match(regex);
//   return match ? match[1] : null;
// }

// function Summary(props) {
//   const intervalId = useRef(null);

//   const authToken = localStorage.getItem("idToken");
//   const profilepicture = localStorage.getItem("profilePic");

//   const { setRl } = useContext(UrlContext);
//   const [input, setInput] = useState("");
//   const [messages, setMessages] = useState([]);
//   const messagesEndRef = useRef(null);
//   const [url, setUrl] = useState("");
//   const [chatVisible, setChatVisible] = useState(false);
//   const [chatHeight, setChatHeight] = useState("auto");
//   const [isTyping, setIsTyping] = useState(false);
//   const [summaryData, setSummaryData] = useState("");
//   const [videourl, setVideoUrl] = useState("");
//   const [iurl, setIurl] = useState("");
//   const [showMenu, setShowMenu] = useState(false);
//   const [loading, setLoading] = useState(true);
//   useEffect(() => {
//     const decodedUrl = decodeURIComponent(props.encodedUrl);
//     setVideoUrl(decodedUrl);
//     console.log("@4");
//   }, [props.encodedUrl]);
//   const fetchSummaryData = useCallback(
//     async (url) => {
//       const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ videourl: url, authtoken: authToken }),
//       };

//       try {
//         const response = await fetch(
//           // "https://ytsummarizer-production-5ff2.up.railway.app/summary",
//           "https://d10kmmydm845tw.cloudfront.net/summary",
//           requestOptions
//         );

//         if (!response.ok) {
//           const errorText = await response.text();
//           if (
//             // response.status === 500 &&
//             errorText.message === "try again later"
//           ) {
//             alert("Summary can't be made");
//             route("/dashboard");
//             return;
//           }
//           throw new Error(
//             `Server responded with ${response.status}: ${errorData.message}`
//           );
//         }

//         const data = await response.json();
//         return data;
//       } catch (error) {
//         console.error("Error fetching summary:", error);
//       }
//     },
//     [authToken]
//   );

//   const handleSignOut = () => {
//     // Clear authentication tokens or user data, e.g., localStorage or cookies
//     localStorage.removeItem("authToken");
//     // Redirect to the login page or any other appropriate page
//     route("/login");
//   };
//   useEffect(() => {
//     if (videourl) {
//       fetchSummaryData(videourl);
//     }
//   }, [videourl, fetchSummaryData]);

//   const lines = summaryData
//     ? summaryData.videosummary.split("\n").map((line) => line.trim())
//     : [];

//   const sections = lines.reduce((acc, line) => {
//     if (line.includes(":")) {
//       acc.push({ subheading: line, bulletPoints: [] });
//     } else if (line.startsWith("-") && acc.length > 0) {
//       acc[acc.length - 1].bulletPoints.push(line.slice(1).trim());
//     }
//     return acc;
//   }, []);

//   console.log(videourl);

//   console.log(summaryData);
//   console.log(videourl);

//   async function handleSubmit(e) {
//     e.preventDefault();

//     try {
//       const response = await fetch(
//         "https://d10kmmydm845tw.cloudfront.net/summarize",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             videoUrl: iurl,
//             authtoken: authToken,
//           }),
//         }
//       );
//       console.log("2");
//       alert("Your Summary will be shown on the Dashboard");
//       const data = await response.json();

//       route("/dashboard");
//     } catch (error) {
//       console.error(error);
//       console.error("Error submitting summary:", error);
//     }
//   }

//   const [isLoadingSummary, setLoadingSummary] = useState(true);
//   const [overloaded, setOverloaded] = useState(false);
//   const [summaryUnavailable, setSummaryUnavailable] = useState(false);

//   const fetchSummaryDataPeriodically = async () => {
//     setLoading(true);
//     const fetchedData = await fetchSummaryData(videourl);

//     if (fetchedData.videosummary === "we are working on it,we promise\n$") {
//       console.log("summary is unavailable");
//       setSummaryUnavailable(true);
//       console.log(summaryUnavailable);
//       setLoading(false);
//       setLoadingSummary(false);
//       clearInterval(intervalId.current); // set summaryUnavailable to true
//     } else if (
//       fetchedData &&
//       fetchedData.videosummary.endsWith("sorry we are a bit overloaded\n$")
//     ) {
//       console.log("Summary is being overloaded");
//       // Add the logic to display your division here
//       // ...

//       // setSummaryUnavailable(true);
//       console.log(summaryUnavailable);
//       setLoading(false);
//       setLoadingSummary(false);

//       setOverloaded(true);
//       setSummaryData(fetchedData);
//       clearInterval(intervalId.current); // clear the interval if the summary is complete
//     } else if (fetchedData && fetchedData.videosummary.includes("$")) {
//       // Remove the '$' string from the summary before setting the state
//       fetchedData.videosummary = fetchedData.videosummary.replace("$", "");
//       setSummaryData(fetchedData);
//       setLoading(false);
//       setLoadingSummary(false); // set loadingSummary to false when $ is replaced
//       clearInterval(intervalId.current); // clear the interval if the summary is complete
//     } else {
//       setSummaryData(fetchedData);
//     }
//   };

//   // Update the useEffect hook
//   useEffect(() => {
//     if (videourl) {
//       fetchSummaryDataPeriodically(); // Fetch data initially
//       const id = setInterval(fetchSummaryDataPeriodically, 500); // Fetch data every 5 seconds
//       intervalId.current = id;

//       return () => {
//         clearInterval(intervalId.current); // Clear the interval when the component is unmounted
//       };
//     }
//   }, [videourl, fetchSummaryData]);

//   const [warningMessage, setWarningMessage] = useState(null);

//   // Modify your sendMessage function
//   const sendMessage = async () => {
//     if (input.trim() === "") return;

//     if (messages.length % 2 !== 0 && messages.length !== 0) {
//       setWarningMessage(
//         "Please wait for the server to respond before sending another message."
//       );
//       setInput("");
//       return;
//     }

//     // Add the user's message as a string to the messages array
//     const updatedMessages = [...messages, input.trim()];
//     setMessages(updatedMessages);

//     setInput("");
//     setIsTyping(true); // Show the typing animation

//     // Prepare request options
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({
//         summary: summaryData.videosummary,
//         authToken: authToken,
//         list: updatedMessages, // Send the entire messages array as 'list'
//       }),
//     };

//     try {
//       // Send the message to the server and receive a response
//       const response = await fetch(
//         // "https://ytsummarizer-production-5ff2.up.railway.app/chat",
//         "https://d10kmmydm845tw.cloudfront.net/chat",
//         requestOptions
//       );

//       if (!response.ok) {
//         if (
//           response.status === 429 ||
//           response.statusText === "we are currently overloaded try again"
//         ) {
//           const botErrorMessage = "Sorry, error occurred";
//           setMessages((prevMessages) => [...prevMessages, botErrorMessage]);
//         }
//         throw new Error("Server responded with a status other than 200");
//       }

//       const responseData = await response.text();
//       let botMessage;

//       try {
//         botMessage = JSON.parse(responseData).message;
//       } catch (error) {
//         botMessage = responseData;
//       }

//       // Add the bot's message as a string to the messages array
//       setMessages((prevMessages) => [...prevMessages, botMessage]);
//       scrollToBottom();
//     } catch (error) {
//       console.error("Error sending message:", error);
//     } finally {
//       setWarningMessage(null);
//       setIsTyping(false); // Hide the typing animation
//       if (!chatVisible) {
//         handleChatToggle();
//       }
//     }
//   };

//   const message1 = () => {
//     const userMessage = "what are the insights of this video";

//     // Add the user's message to the messages array
//     const updatedMessages = [...messages, userMessage];
//     setMessages(updatedMessages);

//     setIsTyping(true); // Show the typing animation

//     // Prepare request options
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({
//         summary: summaryData.videosummary,
//         authToken: authToken,
//         list: updatedMessages, // Send the entire messages array as 'list'
//       }),
//     };

//     // Send the message to the server and receive a response
//     fetch(
//       // "https://ytsummarizer-production-5ff2.up.railway.app/chat",
//       "https://d10kmmydm845tw.cloudfront.net/chat",
//       requestOptions
//     )
//       .then((response) => response.text())
//       .then((responseData) => {
//         let botMessage;

//         try {
//           botMessage = JSON.parse(responseData).message;
//         } catch (error) {
//           botMessage = responseData;
//         }

//         // Add the bot's message as a string to the messages array
//         setMessages((prevMessages) => [...prevMessages, botMessage]);
//         scrollToBottom();
//       })
//       .catch((error) => {
//         console.error("Error sending message:", error);
//       })
//       .finally(() => {
//         setIsTyping(false);
//         setWarningMessage(null); // Hide the typing animation
//         if (!chatVisible) {
//           handleChatToggle();
//         }
//       });
//   };

//   const message2 = () => {
//     const userMessage = "Who is in this video?";

//     // Add the user's message to the messages array
//     const updatedMessages = [...messages, userMessage];
//     setMessages(updatedMessages);

//     setIsTyping(true); // Show the typing animation

//     // Prepare request options
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({
//         summary: summaryData.videosummary,
//         authToken: authToken,
//         list: updatedMessages, // Send the entire messages array as 'list'
//       }),
//     };

//     // Send the message to the server and receive a response
//     fetch("https://d10kmmydm845tw.cloudfront.net/chat", requestOptions)
//       .then((response) => response.text())
//       .then((responseData) => {
//         let botMessage;

//         try {
//           botMessage = JSON.parse(responseData).message;
//         } catch (error) {
//           botMessage = responseData;
//         }

//         // Add the bot's message as a string to the messages array
//         setMessages((prevMessages) => [...prevMessages, botMessage]);
//         scrollToBottom();
//       })
//       .catch((error) => {
//         console.error("Error sending message:", error);
//       })
//       .finally(() => {
//         setIsTyping(false);
//         setWarningMessage(null); // Hide the typing animation
//         if (!chatVisible) {
//           handleChatToggle();
//         }
//       });
//   };

//   const message3 = () => {
//     const userMessage = "What is the main message of this video?";

//     // Add the user's message to the messages array
//     const updatedMessages = [...messages, userMessage];
//     setMessages(updatedMessages);

//     setIsTyping(true); // Show the typing animation

//     // Prepare request options
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({
//         summary: summaryData.videosummary,
//         authToken: authToken,
//         list: updatedMessages, // Send the entire messages array as 'list'
//       }),
//     };

//     // Send the message to the server and receive a response
//     fetch(
//       // "https://ytsummarizer-production-5ff2.up.railway.app/chat",
//       "https://d10kmmydm845tw.cloudfront.net/chat",
//       requestOptions
//     )
//       .then((response) => response.text())
//       .then((responseData) => {
//         let botMessage;

//         try {
//           botMessage = JSON.parse(responseData).message;
//         } catch (error) {
//           botMessage = responseData;
//         }

//         // Add the bot's message as a string to the messages array
//         setMessages((prevMessages) => [...prevMessages, botMessage]);
//         scrollToBottom();
//       })
//       .catch((error) => {
//         console.error("Error sending message:", error);
//       })
//       .finally(() => {
//         setIsTyping(false);
//         setWarningMessage(null); // Hide the typing animation
//         if (!chatVisible) {
//           handleChatToggle();
//         }
//       });
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === "Enter") {
//       sendMessage();
//     }
//   };

//   const targetRef = useRef(null);

//   const scrollToDiv = () => {
//     if (targetRef.current) {
//       targetRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   };

//   const [scrollPosition, setScrollPosition] = useState(0);
//   const [maxHeight, setMaxHeight] = useState("82%");

//   const handleScroll = () => {
//     const position = window.pageYOffset;
//     setScrollPosition(position);
//   };

//   useEffect(() => {
//     if (scrollPosition > 0) {
//       setMaxHeight("99%");
//     } else {
//       setMaxHeight("82%");
//     }
//     window.addEventListener("scroll", handleScroll, { passive: true });

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [scrollPosition]);

//   const downloadPdf = async () => {
//     const doc = new jsPDF();
//     const margin = 20; // Define margin size
//     const maxWidth = doc.internal.pageSize.getWidth() - 2 * margin; // Maximum width of content

//     // Set the font and its size
//     doc.setFont("Roboto"); // Make sure to load this font first

//     // Add the header
//     doc.setFontSize(22); // Increase font size for header
//     doc.setTextColor(0, 0, 255); // Set the color to blue
//     doc.text("Sentora.ai, Document your learning", margin, margin);

//     // Add the video summary text
//     doc.setTextColor(0, 0, 0); // Set the color to black
//     doc.setFontSize(12); // Set the font size for the summary

//     const summaryLines = doc.splitTextToSize(
//       summaryData.videosummary,
//       maxWidth
//     ); // Split the summary into lines
//     let y = 28; // Calculate the y coordinate for the first line

//     summaryLines.forEach((line) => {
//       if (y > doc.internal.pageSize.getHeight() - margin) {
//         doc.addPage();
//         y = margin;
//       }
//       doc.text(line, margin, y);
//       y += 5;
//     });

//     // The rest of your PDF generation code goes here...

//     // Add the footer to each page
//     const pageCount = doc.getNumberOfPages();
//     for (let i = 1; i <= pageCount; i++) {
//       doc.setPage(i);
//       doc.text(`Page ${i}`, margin, doc.internal.pageSize.getHeight() - 16);
//     }

//     doc.save("download.pdf");
//   };

//   return (
//     // <div className={style.background}>
//     <div>
//       <div className=" flex flex-col">
//         <div className={style.header}>
//           <div className=" flex  justify-between mx-8 py-4 md:pb-[3rem] md:pt-8">
//             <div className=" font-medium  h-4 w-auto  text-slate-50">
//               <Link href="/dashboard">
//                 <img
//                   src="../../assets/logo.png"
//                   alt="Sentora Logo"
//                   // width="156px"
//                   className=" w-44 ml-8 hidden md:block"
//                 />
//                 <img
//                   src="../../assets/logo3.png"
//                   alt="Sentora Logo"
//                   // width="156px"

//                   className=" w-10 mr-4  visible sm:hidden"
//                 />
//               </Link>
//             </div>
//             <div>
//               <div className="mx-4 sm:mx-96">{/* search begins */}</div>
//               <div className=" hidden md:block">
//                 <div className={style.input}>
//                   <div className="relative m-1.5 pl-2 rounded-md shadow-sm flex">
//                     <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                       <FontAwesomeIcon icon={faLink} className="text-white" />
//                     </div>

//                     <input
//                       type="text"
//                       placeholder="Paste link to YouTube video here "
//                       className="w-full bg-[#131524] text-white rounded-md sm:py-2 hidden md:block py-1 mr-1 pl-10 pr-20"
//                       onChange={(e) => setIurl(e.target.value)} // Add this line
//                     />
//                     <input
//                       type="text"
//                       placeholder="Paste link here "
//                       className="w-full bg-[#131524] text-white rounded-md  block md:hidden py-1 mr-0 pl-10 "
//                       onChange={(e) => setIurl(e.target.value)} // Add this line
//                     />

//                     <button
//                       className={style.button}
//                       onClick={() => {
//                         setRl(iurl);
//                         route("/dashboard");
//                       }}
//                     >
//                       Summarize
//                     </button>
//                     <button
//                       className={style.button2}
//                       onClick={() => {
//                         setRl(iurl);
//                         route("/dashboard");
//                       }}
//                     >
//                       →
//                     </button>
//                   </div>
//                 </div>
//               </div>
//               {/* search ends */}
//             </div>

//             <div className="text-white ">
//               {" "}
//               <div class="relative">
//                 <img
//                   class="h-12 w-12 mr-4 rounded-full cursor-pointer"
//                   src={profilepicture}
//                   alt=""
//                   onClick={() => setShowMenu(!showMenu)}
//                 />
//                 {showMenu && (
//                   <div class="absolute top-14 right-4  w-36 z-[1000] bg-white text-black p-2 rounded-lg shadow-lg">
//                     <ul className=" font-medium p-3">
//                       <li>
//                         <a href="/user" className=" hover:text-indigo-600 ">
//                           Profile
//                         </a>
//                       </li>
//                       <li>
//                         <button
//                           onClick={handleSignOut}
//                           className=" hover:text-indigo-600 "
//                         >
//                           Sign Out
//                         </button>
//                       </li>
//                     </ul>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* {!loading && summaryData ? ( */}
//         <div>
//           <div className="bg-white flex-grow">
//             <div className="flex flex-col md:flex-row">
//               {/* Video player and summary section */}
//               {/* <div classname="max-w-3xl mx-auto px-2 md:px-0 flex-grow"> */}
//               <div className="max-w-3xl mx-auto px-2  flex-grow">
//                 <div className="sticky top-0 bg-white z-10 border-gray-200 border-b-[1px]">
//                   <div className=" mx-auto py-2  sm:py-4  flex-grow-4 items-center">
//                     <div className="flex items-center">
//                       <h2 className="text-1xl font-bold text-gray-900 tracking-tight sm:text-3xl flex-grow">
//                         {summaryData.title}
//                         {summaryUnavailable && (
//                           <div>
//                             <h2 className="text-2xl font-bold text-gray-900 tracking-tight sm:text-3xl flex-grow">
//                               This video is Unavailable
//                             </h2>
//                           </div>
//                         )}
//                       </h2>

//                       <button
//                         className="text-gray-700 hover:text-gray-800"
//                         onClick={() => {
//                           if (navigator.clipboard) {
//                             navigator.clipboard
//                               .writeText(summaryData.videosummary)
//                               .then(() => {
//                                 alert("Summary copied to clipboard!");
//                               })
//                               .catch((err) => {
//                                 console.error("Failed to copy text: ", err);
//                               });
//                           } else {
//                             const textarea = document.createElement("textarea");
//                             textarea.value = summaryData.videosummary;
//                             document.body.appendChild(textarea);
//                             textarea.select();
//                             try {
//                               document.execCommand("copy");
//                               alert("Summary copied to clipboard!");
//                             } catch (err) {
//                               console.error("Failed to copy text: ", err);
//                             } finally {
//                               document.body.removeChild(textarea);
//                             }
//                           }
//                         }}
//                       >
//                         <span className="">
//                           {" "}
//                           <img
//                             src="../../assets/copy.png"
//                             alt="Copy"
//                             // width="156px"
//                             className="  w-12"
//                           />
//                         </span>
//                       </button>

//                       <span>
//                         {" "}
//                         <button onClick={downloadPdf}>
//                           <span className="">
//                             <img
//                               src="../../assets/downloadlogo.png"
//                               alt="Download"
//                               // width="156px"
//                               className="  w-[2.6rem] "
//                             />
//                           </span>
//                         </button>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="flex justify-center items-center w-full bg-white pt-6">
//                   <div className="relative w-full py-4 sm:py-0 md:w-5/5 lg:w-4/4">
//                     <div
//                       className="relative pt-9 pb-16 sm:pb-56 lg:pb-32"
//                       style={{ paddingTop: "50%" }}
//                     >
//                       <iframe
//                         className="absolute inset-0 w-full h-full rounded-[0.5rem]  "
//                         src={`https://www.youtube.com/embed/${getVideoId(
//                           videourl
//                         )}`}
//                         title="title"
//                         frameBorder="0"
//                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                         allowFullScreen
//                       ></iframe>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="flex justify-center">
//                   <button
//                     onClick={scrollToDiv}
//                     class="fixed bottom-5  z-50 left-1/2 transform -translate-x-1/2 bg-[#051367] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full md:hidden lg:hidden"
//                   >
//                     Chat
//                   </button>

//                   {/* <button
//                     onClick={scrollToDiv}
//                     class="bg-[#051367] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded  md:hidden lg:hidden"
//                   >
//                     Chat
//                   </button> */}
//                 </div>

//                 {/* <div className="text-center">
//                 <h3 className="text-3xl font-medium mt-8 text-gray-900 tracking-tight">
//                   Summary
//                 </h3>
//               </div> */}

//                 <div className="mt-8 text-md md:text-xl text-gray-800">
//                   {summaryUnavailable && (
//                     <div>
//                       <p className="text-xl text-center">
//                         Video Trouble? Our Team is Here! Click Here to Help Us
//                         Fix It. Please note, we can't generate summaries for
//                         videos without captions.{" "}
//                       </p>
//                       <div className="flex justify-center">
//                         <a href="https://forms.gle/crtzixr9kChNJ5488">
//                           <button className="bg-blue-600 text-white rounded-full m-4 py-2 px-4">
//                             Submit Error
//                           </button>
//                         </a>
//                       </div>
//                     </div>
//                   )}
//                   {summaryData.videosummary === "summary being prepared" ? (
//                     <div className="flex ">
//                       Summary is being prepared...
//                       {/* <div
//                         class="inline-block text-[#2D31FA]   h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
//                         role="status"
//                       ></div> */}
//                     </div>
//                   ) : (
//                     <div className="relative">
//                       <div className="absolute top-0 left-0 w-full h-full bg-white z-10">
//                         {sections.map((section, index) => {
//                           const { subheading, bulletPoints } = section;
//                           return (
//                             <div key={index} className="mb-4">
//                               <div className="font-bold pl-4 mt-4 mb-2 clip-text">
//                                 {index + 1}. {subheading}
//                               </div>
//                               <ul className="list-disc pl-8">
//                                 {bulletPoints.map((point, i) => (
//                                   <li
//                                     key={i}
//                                     className="mb-2 ml-[1rem] clip-text"
//                                   >
//                                     {point}
//                                   </li>
//                                 ))}
//                               </ul>
//                             </div>
//                           );
//                         })}
//                       </div>

//                       <div>
//                         {sections.map((section, index) => {
//                           const { subheading, bulletPoints } = section;
//                           return (
//                             <div key={index} className="mb-4">
//                               <div className="font-bold  pl-4 mt-4 mb-2">
//                                 {index + 1}. {subheading}
//                               </div>
//                               <ul className="list-disc pl-8">
//                                 {bulletPoints.map((point, i) => (
//                                   <li key={i} className="mb-2 ml-[1rem]">
//                                     {point}
//                                   </li>
//                                 ))}
//                               </ul>
//                             </div>
//                           );
//                         })}
//                       </div>
//                     </div>
//                   )}
//                 </div>
//                 {overloaded && (
//                   <div>
//                     <p className="text-xl text-blue-700 mt-4 text-center">
//                       Our servers are overloaded right now so we can't provide
//                       you with the whole summary. Our team is working on it.
//                     </p>
//                     <div className="flex justify-center">
//                       {/* <a href="https://forms.gle/UkvrYBnRzYzcBPm88"> */}
//                       <a href="https://forms.gle/crtzixr9kChNJ5488">
//                         <button className="bg-blue-600 text-white rounded-full m-4 py-2 px-4">
//                           Submit Response
//                         </button>
//                       </a>
//                     </div>
//                   </div>
//                 )}

//                 {isLoadingSummary && (
//                   <div className="flex justify-start space-x-2 my-4 py-4 mx-6">
//                     <div className="w-2 h-2 bg-gray-800 rounded-full animate-bounce delay-150"></div>
//                     <div className="w-2 h-2 bg-gray-800 rounded-full animate-bounce delay-300"></div>
//                     <div className="w-2 h-2 bg-gray-800 rounded-full animate-bounce delay-450"></div>
//                   </div>
//                 )}
//               </div>

//               {/* Chat component */}
//               {/* <div id="target" ref={targetRef}> */}

//               <div
//                 className="w-full sm:w-1/3  sticky flex flex-col  "
//                 id="target"
//                 ref={targetRef}
//               >
//                 <div className="sticky top-0">
//                   {" "}
//                   {/* <div className="chat-container h-screen border-4 md:border-0 rounded-md md:rounded-none my-4 md:my-0 ">
//                     <h2 class="bg-[#051367]  text-white border-4  text-center font-bold  text-xl py-2 px-auto rounded-md  md:hidden lg:hidden">
//                       Chat
//                     </h2>
//                     <div className="max-w-3xl  h-full flex flex-col ">
//                       <div
//                         className="bg-white h-full  border-l-2 overflow-y-scroll transition-all duration-500"
//                         style={{
//                           maxHeight: "calc(82% - 3rem)",
//                         }}
//                       > */}
//                   <div className="chat-container h-screen border-4 md:border-0 rounded-md md:rounded-none my-4 md:my-0 ">
//                     <h2 class="bg-[#051367]  text-white border-4  text-center font-bold  text-xl py-2 px-auto rounded-md  md:hidden lg:hidden">
//                       Chat
//                     </h2>
//                     <div className="max-w-3xl  h-full flex flex-col ">
//                       <div
//                         className="bg-white h-full  border-l-2 overflow-y-scroll transition-all duration-500"
//                         style={{
//                           maxHeight: `calc(${maxHeight} - 3rem)`,
//                         }}
//                       >
//                         {/* ... */}
//                         {/* Messages */}
//                         {messages.length === 0 ? (
//                           <div className="text-center text-gray-500 p-4">
//                             <img
//                               src="../../assets/yt-q.png"
//                               alt="YT image Logo"
//                               // width="156px"
//                               className=" w-80 mx-auto"
//                             />
//                             <p className=" text-xl font-medium p-2">
//                               <span> Still have doubts?</span>{" "}
//                               <span className=" text-indigo-600">
//                                 Ask the video!
//                               </span>{" "}
//                             </p>
//                             <button
//                               className="w-full cursor-pointer"
//                               onClick={message1}
//                             >
//                               <p class=" text-lg text-indigo-600 border-[1px] m-2 rounded-sm border-[#CED2FF]  p-2 ">
//                                 What are the key insights?
//                               </p>
//                             </button>
//                             <button
//                               className="w-full cursor-pointer"
//                               onClick={message2}
//                             >
//                               <p class=" text-lg text-indigo-600 border-[1px] m-2 rounded-sm border-[#CED2FF]  p-2">
//                                 Who is in this video?
//                               </p>
//                             </button>
//                             <button
//                               className="w-full cursor-pointer"
//                               onClick={message1}
//                             >
//                               <p class=" text-lg text-indigo-600 border-[1px] m-2 rounded-sm border-[#CED2FF]  p-2">
//                                 What is the main message?
//                               </p>
//                             </button>
//                           </div>
//                         ) : (
//                           messages.map((message, index) => (
//                             <div
//                               key={index}
//                               className={`${
//                                 index % 2 === 0
//                                   ? "text-right rounded-3xl"
//                                   : "text-left rounded-3xl border-red-500"
//                               } mb-2 mt-1 mx-2`}
//                             >
//                               <span
//                                 className={`${
//                                   index % 2 === 0
//                                     ? "bg-[#2D31FA] text-white text-left rounded-3xl rounded-br-none "
//                                     : "bg-white rounded-3xl border-2   text-gray-800 border-[#2D31FA]   rounded-bl-none"
//                                 } text-black inline-block px-3 py-2 mx-2`}
//                               >
//                                 {message}
//                               </span>
//                             </div>
//                           ))
//                         )}

//                         {warningMessage && (
//                           <div className="text-left mb-2 mt-1 mx-2">
//                             <span className="bg-red-600 text-white  rounded-3xl  inline-block px-3 py-2 mx-2">
//                               {warningMessage}
//                             </span>
//                           </div>
//                         )}
//                         <div>
//                           {isTyping && (
//                             <span className="text-gray-900 mx-2 text-5xl inline-block animate-pulse">
//                               ...
//                             </span>
//                           )}
//                         </div>
//                         <div ref={messagesEndRef}></div>
//                       </div>
//                       <div className=" flex border-2">
//                         <input
//                           className="w-full px-4 m-2 py-2"
//                           type="text"
//                           value={input}
//                           onChange={(e) => setInput(e.target.value)}
//                           onKeyPress={handleKeyPress}
//                           placeholder="Type your message"
//                         />
//                         <button
//                           className={`m-2 py-2 px-4 font-bold text-white rounded-md ${
//                             warningMessage
//                               ? "bg-gray-400 cursor-not-allowed"
//                               : "bg-[#051367]"
//                           }`}
//                           onClick={warningMessage ? null : sendMessage}
//                           disabled={warningMessage}
//                         >
//                           →
//                         </button>

//                         {/* <button
//                           className=" m-2 py-2 px-4 font-bold text-white rounded-md bg-[#051367]"
//                           onClick={sendMessage}
//                         >
//                           →
//                         </button> */}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 {/* </div> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="bg-white">
//         <Footer></Footer>
//       </div>
//     </div>
//   );
// }

// export default Summary;
