import style from "./user.css";
import { h } from "preact";
import { route } from "preact-router";
import { Link } from "preact-router";
import { UrlContext } from "../context/UrlContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useContext } from "preact/hooks";
import { data } from "autoprefixer";

const User = ({ videoUrl, setVideoUrl }) => {
  const authtoken = localStorage.getItem("idToken");
  const profilepicture = localStorage.getItem("profilePic");
  const user_phonenumber = localStorage.getItem("phoneNumber");
  const user_email = localStorage.getItem("email");
  const user_name = localStorage.getItem("name");
  const [iurl, setIurl] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [occupation, setOccupation] = useState("Occupation");
  const [organization, setOrganization] = useState("Organization");
  const { rl, setRl } = useContext(UrlContext);
  const [ref, setRef] = useState("");

  const handleSignOut = () => {
    // Clear authentication tokens or user data, e.g., localStorage or cookies
    localStorage.removeItem("authToken");
    // Redirect to the login page or any other appropriate page
    route("/login");
  };

  const [user_post, setPost] = useState("");
  const [user_workplace, setWorkplace] = useState("");

  useEffect(() => {
    console.log(rl);
  }, [rl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://d10kmmydm845tw.cloudfront.net/userpage",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              authtoken: authtoken,
            }),
          }
        );
        console.log(response);
        const data = await response.json();
        setRef(data.ref);
        console.log(data);
        console.log(data.user_post);

        setPost(data.user_post);

        setWorkplace(data.user_workplace);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [occupation, organization]);

  const [modal, setModal] = useState(false);

  const editprofile = () => {
    console.log("4");
    setModal(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setModal(false);
    try {
      const response = await fetch(
        "https://d10kmmydm845tw.cloudfront.net/user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            authToken: authtoken,
            occupation: occupation,
            organization: organization,
          }),
        }
      );
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={style.background}>
      <div className=" h-max ">
        <div className={style.header}>
          <div className=" flex  justify-between mx-8 pt-8">
            <div className=" font-medium  h-4 w-auto  text-slate-50">
              <Link href="/dashboard">
                <img
                  src="../../assets/logo.png"
                  alt="Sentora Logo"
                  // width="156px"
                  className=" w-44 ml-8 hidden md:block"
                />
                <img
                  src="../../assets/logo3.png"
                  alt="Sentora Logo"
                  // width="156px"

                  className=" w-10 mr-4  visible sm:hidden"
                />
              </Link>
            </div>
            <div>
              <div className="mx-4 sm:mx-96">{/* search begins */}</div>

              <div className={style.input}>
                <div className="relative m-1.5  rounded-md shadow-sm flex">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FontAwesomeIcon icon={faLink} className="text-white" />
                  </div>

                  <input
                    type="text"
                    placeholder="Paste link to YouTube video here "
                    className="w-full bg-[#131524] text-white rounded-md sm:py-2 hidden md:block py-1 mr-1 pl-10 pr-20"
                    onChange={(e) => setIurl(e.target.value)} // Add this line
                  />
                  <input
                    type="text"
                    placeholder="Paste link here "
                    className="w-full bg-[#131524] text-white rounded-md  block md:hidden py-1 mr-0 pl-10 "
                    onChange={(e) => setIurl(e.target.value)} // Add this line
                  />

                  {/* <button
                    className={style.button}
                    onClick={() => {
                      setVideoUrl(iurl);
                      console.log(videoUrl);
                      route("/dashboard");
                    }}
                    //  onClick={handleSubmit}
                  >
                    Summarize
                  </button> */}
                  <div className="md:hidden block">
                    <button
                      className={style.button}
                      onClick={() => {
                        setRl(iurl);
                        console.log(rl);
                        route("/dashboard");
                      }}
                    >
                      →
                    </button>
                  </div>
                  <button
                    className={style.button}
                    onClick={() => {
                      console.log(iurl);
                      setRl(iurl);
                      console.log("a");
                      console.log(rl);
                      console.log("b");
                      route("/dashboard");
                    }}
                  >
                    Summarize
                  </button>
                </div>
              </div>

              {/* search ends */}
            </div>

            <div className="text-white ">
              {" "}
              <div class="relative">
                <img
                  class="h-12 w-12 mr-4 rounded-full cursor-pointer"
                  src={profilepicture}
                  alt=""
                  onClick={() => setShowMenu(!showMenu)}
                />
                {showMenu && (
                  <div class="absolute top-14 right-4  w-36 z-10 bg-white text-black p-2 rounded-lg shadow-lg">
                    <ul className=" font-medium p-3">
                      <li>
                        <a href="/user" className=" hover:text-indigo-600">
                          Profile
                        </a>
                      </li>
                      <li>
                        <button
                          onClick={handleSignOut}
                          className=" hover:text-indigo-600"
                        >
                          Sign Out
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="border-b-4  text-[#051367] shadow-sm text-4xl py-4 px-16">
          Profile
        </div>

        <div className="flex  bg-white border-[1px] border-[#CED2FF] rounded-md my-8 mx-8 md:mx-36 flex-col center">
          <div>
            <div className="flex  justify-between border-b-[1px]  border-[#CED2FF]  mx-12 my-8 ">
              <div className="flex">
                <div>
                  <img
                    src={profilepicture}
                    alt="Preact Logo"
                    height="106px"
                    width="106px"
                    className=" rounded-full"
                  />
                </div>
                <div className=" ">
                  <div className="flex flex-col mx-6 mb-4 p-4 ">
                    <div className="md:text-2xl text-sm text-black ">
                      {user_name}
                    </div>
                    <div className=" text-xs md:text-1xl   text-gray-600 font-light">
                      {user_post}
                    </div>
                    <div className="md:text-1xl text-xs  text-gray-600 font-extralight">
                      {user_workplace}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={editprofile}
                  className="border-2 border-blue-800 rounded-lg py-2 px-4 text-blue-800"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="flex md:flex-row flex-col  mx-1 md:mx-12 mb-4 justify-between">
              <div className="md:text-2xl text-sm">
                {" "}
                <div className="flex justify-around text-center  pr-2 md:pr-36 mx-8 md:mx-0 py-6 md:py-0 border-b-[1px] md:border-b-0 md:border-r-[1px] border-[#CED2FF] ">
                  <div>
                    <img
                      src="../../assets/@.png"
                      alt="Preact Logo"
                      height="104px"
                      width="104px"
                      className=" rounded-full"
                    />
                  </div>
                  <div className="mt-[2.5rem] ml-12  "> {user_email}</div>
                </div>
              </div>
              <div className="md:text-2xl text-sm">
                {" "}
                <div className="flex justify-around text-center ">
                  <div className=" mt-[2.5rem] mx-12">{user_phonenumber}</div>
                  <div>
                    <img
                      src="../../assets/call.png"
                      alt="Preact Logo"
                      height="104px"
                      width="104px"
                      className=" rounded-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col items-center border-2 rounded-md my-4 p-4">
          <h2 className="text-2xl mb-4">Referral System</h2>
          <p className="mb-4">
            Share your referral code with your friends! You get +3 credits after
            every successful referral.
          </p>
          <div className="border-2 rounded-md p-2 my-2">{ref}</div>
 
        </div> */}
        <div>
          <div className=" border-b-4 text-[#051367] shadow-sm text-4xl py-4 px-16">
            Referral
          </div>
          <div>
            <div className="flex bg-white border-[1px] border-[#CED2FF] rounded-md m-8 md:mx-36 flex-col justify-center">
              <div className="mx-12 my-8">
                <div className="md:text-2xl text-sm text-black text-center">
                  Share your referral code with your friends! You get +3 credits
                  after every successful referral.
                </div>
              </div>
              <div className="text-center mx-1 md:mx-12 mb-8">
                <div className="md:text-2xl text-sm">
                  <div className="flex justify-center text-justify mx-8 md:mx-0 md:py-0">
                    <div className="mt-[2.5rem]">{ref}</div>
                    <button
                      className="text-gray-700 hover:text-gray-800"
                      onClick={() => {
                        if (navigator.clipboard) {
                          navigator.clipboard
                            .writeText(ref)
                            .then(() => {
                              alert("Reference id copied to clipboard!");
                            })
                            .catch((err) => {
                              console.error("Failed to copy text: ", err);
                            });
                        } else {
                          const textarea = document.createElement("textarea");
                          textarea.value = summaryData.videosummary;
                          document.body.appendChild(textarea);
                          textarea.select();
                          try {
                            document.execCommand("copy");
                            alert("Summary copied to clipboard!");
                          } catch (err) {
                            console.error("Failed to copy text: ", err);
                          } finally {
                            document.body.removeChild(textarea);
                          }
                        }
                      }}
                    >
                      <span className="">
                        {" "}
                        <img
                          src="../../assets/copy.png"
                          alt="Copy"
                          className="w-10 pl-2 pt-8 mt-2"
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {modal && (
          <div className=" z-20 top-1/2  left-1/2">
            <div className="flex flex-col  text-center w-[32rem] mx-auto ">
              <div className={style.box}>
                <div className="flex justify-center mt-4">
                  <img
                    src={profilepicture}
                    alt="Preact Logo"
                    height="128px"
                    width="128px"
                    className=" rounded-full"
                  />
                </div>
                <div className="font-medium text-lg mb-8">Lorem Ipsum</div>
                <div className="flex justify-between mx-8 my-4 pb-4 border-b-2 border-gray-300">
                  <div>Email</div>
                  <div>lorem.ipsrum@oanf.com</div>
                </div>
                <div className="flex justify-between mx-8 my-4 pb-4  border-b-2 border-gray-300">
                  <div>Mobile number</div>
                  <div>829529521935</div>
                </div>
                <div className="flex justify-between mx-8 my-4 pb-4  border-b-2 border-gray-300">
                  <div>Occupation</div>
                  <div>
                    <input type="text" className=" border-gray-600">
                      sdgsdf
                    </input>
                  </div>
                </div>
                <div className="flex justify-between mx-8 mt-4 mb-8 pb-4  border-b-2 border-gray-300">
                  <div>Organization</div>
                  <div>Lorem Ipsum Pvt. Ltd.</div>
                </div>
              </div>
            </div>
          </div>
        )} */}

        {modal && (
          <>
            <div className="fixed inset-0 blur-3xl bg-black opacity-50"></div>
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="bg-white p-5 rounded-xl shadow-lg w-full max-w-md m-auto relative">
                <button
                  onClick={() => setModal(false)}
                  className="absolute right-2 top-2 mx-2 font-bold text-gray-700 hover:text-red-500"
                >
                  X
                </button>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block text-gray-700">Occupation</label>
                    <input
                      type="text"
                      onChange={(e) => setOccupation(e.target.value)}
                      className="mt-2 px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500 w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Organization</label>
                    <input
                      type="text"
                      onChange={(e) => setOrganization(e.target.value)}
                      className="mt-2 px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500 w-full"
                    />
                  </div>
                  <button
                    type="submit"
                    className="mt-4 px-4 py-2 bg-blue-800 text-white rounded-lg w-full"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default User;
