import { h, Component } from "preact";
import { route } from "preact-router";
import {
  signInWithGoogle,
  signInWithPhoneNumberOTP,
  resendOTP,
} from "../../../Firebase";
import style from "./login.css";
import countries from "../../components/country.json";

import { useState, useEffect } from "react";

export default class Login extends Component {
  state = {
    phoneNumber: "",
    countryCode: "+91",
    otp: "",
    name: "",
    email: "",
    confirmationResult: null,
    isOtpSent: false,
    isOtpConfirmed: false,
    isNewUser: null,
    isApproved: null, // Add this field
    referralCode: "",
    referralSignIn: false,
  };

  handleSignInWithGoogleReferral = () => {
    console.log("Google Sign in with referral initiated");
    signInWithGoogle()
      .then(({ isNewUser, name, email, profilePic, idToken }) => {
        if (true) {
          this.setState({
            name,
            email,
            profilePic,
            idToken,
            isNewUser,
            referralSignIn: true,
          });
          localStorage.setItem("isNewUser", "true");
          localStorage.setItem("idToken", idToken);
          localStorage.setItem("name", name);
          localStorage.setItem("email", email);
        } else {
          console.log("User is not new, cannot use a referral code.");
        }
      })
      .catch((error) => {
        console.error("Error signing in with Google:", error);
      });
  };

  handleSignInWithGoogle = () => {
    console.log("Google Sign in initiated");
    signInWithGoogle()
      .then(({ isNewUser, name, email, profilePic, idToken }) => {
        this.setState({ name, email, profilePic, idToken });

        console.log("Google Sign in Successful. Received user data:", {
          isNewUser,
          name,
          email,
          profilePic,
          idToken,
        });

        fetch("https://d10kmmydm845tw.cloudfront.net/login", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            authtoken: idToken,
            email: email,
            name: name,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            const { waitlist, newuser } = data;
            this.setState({ isApproved: waitlist, isNewUser: newuser });

            if (waitlist && newuser) {
              console.log("User is new, show phone number input field...");
              this.setState({ isOtpSent: false, isOtpConfirmed: false });
              // Here, we're setting the isNewUser and isOtpSent state variables correctly to initiate the OTP flow.
            } else if (waitlist && !newuser) {
              console.log("User is not new, starting signup process...");

              const data = {
                authtoken: idToken,
                name: this.state.name,
                email: this.state.email,
                // phoneNumber: this.state.phoneNumber, // Make sure to update phoneNumber state when OTP flow is completed
                organization: null,
                occupation: null,
                limit: null,
              };

              fetch("https://d10kmmydm845tw.cloudfront.net/signup", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.text())
                .then((data) => {
                  console.log(
                    "Received response from server after signup:",
                    data
                  );
                  if (data === "signed up") {
                    console.log("Signed up, redirecting to dashboard...");
                    localStorage.setItem("isNewUser", "false");
                    route("/dashboard");
                  }
                })
                .catch((error) => {
                  console.error("Error during signing up:", error);
                });
            } else {
              console.log("User is not approved. Cannot proceed with login.");
              // Handle not approved user scenario here
            }
          })
          .catch((error) =>
            console.error("Error checking if user is approved:", error)
          );
      })
      .catch((error) => {
        console.error("Error signing in with Google:", error);
      });
  };

  // handleSignInWithGoogle = () => {
  //   console.log("Google Sign in initiated");
  //   signInWithGoogle()
  //     .then(({ isNewUser, name, email, profilePic, idToken }) => {
  //       this.setState({ name, email, profilePic, idToken });

  //       console.log("Google Sign in Successful. Received user data:", {
  //         isNewUser,
  //         name,
  //         email,
  //         profilePic,
  //         idToken,
  //       });

  //       fetch("https://d10kmmydm845tw.cloudfront.net/login", {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({ authtoken: idToken, email: email }),
  //       })
  //         .then((response) => response.json())
  //         .then((data) => {
  //           const { waitlist, newuser } = data;
  //           this.setState({ isApproved: waitlist, isNewUser: newuser });

  //           if (waitlist && newuser) {
  //             console.log("User is new, show phone number input field...");
  //             this.setState({ isOtpSent: false, isOtpConfirmed: false });
  //             // Here, we're setting the isNewUser and isOtpSent state variables correctly to initiate the OTP flow.
  //           } else if (waitlist && !newuser) {
  //             console.log("User is not new, redirecting to dashboard...");
  //             console.log(this.state);

  //             route("/dashboard");
  //           } else {
  //             console.log("User is not approved. Cannot proceed with login.");
  //             // Handle not approved user scenario here
  //           }
  //         })
  //         .catch((error) =>
  //           console.error("Error checking if user is approved:", error)
  //         );

  //       // fetch("https://d10kmmydm845tw.cloudfront.net/login", {
  //       //   method: "POST",
  //       //   headers: { "Content-Type": "application/json" },
  //       //   body: JSON.stringify({ authtoken: idToken, email: email }),
  //       // })
  //       //   .then((response) => response.json())
  //       //   .then((data) => {
  //       //     const { waitlist, newuser } = data;
  //       //     // Update isApproved field based on waitlist value
  //       //     this.setState({ isApproved: waitlist, isNewUser: newuser });

  //       //     if (waitlist) {
  //       //       if (!newuser) {
  //       //         console.log("User is not new, redirecting to dashboard...");
  //       //         console.log(this.state);
  //       //         route("/dashboard");
  //       //       } else {
  //       //         console.log("User is new, show phone number input field...");
  //       //         this.setState({ isOtpSent: false, isOtpConfirmed: false });
  //       //         // Additional logic for OTP flow
  //       //       }
  //       //     } else {
  //       //       console.log("User is not approved. Cannot proceed with login.");
  //       //       // Handle not approved user scenario here
  //       //     }
  //       //   })
  //       //   .catch((error) =>
  //       //     console.error("Error checking if user is approved:", error)
  //       //   );
  //     })
  //     .catch((error) => {
  //       console.error("Error signing in with Google:", error);
  //     });
  // };

  // state = {
  //   phoneNumber: "",
  //   countryCode: "+91",
  //   otp: "",
  //   name: "",
  //   email: "",
  //   confirmationResult: null,
  //   isOtpSent: false,
  //   isOtpConfirmed: false,
  //   isNewUser: null,
  //   isApproved: null, // Add this field
  // };

  // handleSignInWithGoogle = () => {
  //   console.log("Google Sign in initiated");
  //   signInWithGoogle()
  //     .then(({ isNewUser, name, email, profilePic, idToken }) => {
  //       this.setState({ name, email, profilePic, idToken });

  //       console.log("Google Sign in Successful. Received user data:", {
  //         isNewUser,
  //         name,
  //         email,
  //         profilePic,
  //         idToken,
  //       });

  //       fetch(
  //         // "https://ytsummarizer-production-5ff2.up.railway.app/login",

  //         "https://d10kmmydm845tw.cloudfront.net/login",
  //         {
  //           method: "POST",
  //           headers: { "Content-Type": "application/json" },
  //           body: JSON.stringify({ authtoken: idToken }),
  //         }
  //       )
  //         .then((response) => response.text()) // Updated this line
  //         .then((isNewUserLogin) => {
  //           console.log(
  //             "Received response from server after login:",
  //             isNewUserLogin
  //           );
  //           const isNewUser = isNewUserLogin === "true";
  //           // const isNewUser = "true"; // Updated this line
  //           this.setState({ isNewUser });

  //           if (!isNewUser) {
  //             console.log("User is not new, redirecting to dashboard...");
  //             console.log(this.state);
  //             route("/dashboard");
  //           } else {
  //             console.log("User is new, show phone number input field...");
  //           }
  //         })
  //         .catch((error) =>
  //           console.error("Error checking if user is new:", error)
  //         );
  //     })
  //     .catch((error) => {
  //       console.error("Error signing in with Google:", error);
  //     });
  // };

  handleSignInWithPhoneNumber = () => {
    console.log("Phone Sign in initiated");
    if (!this.state.isOtpSent) {
      console.log("Sending OTP");
      const fullPhoneNumber = this.state.countryCode + this.state.phoneNumber;
      console.log(fullPhoneNumber);
      signInWithPhoneNumberOTP(fullPhoneNumber).then((result) => {
        console.log("OTP sent. Result:", result);
        this.setState({ confirmationResult: result, isOtpSent: true });
      });
    } else if (!this.state.isOtpConfirmed) {
      console.log("Confirming OTP");
      const confirmationResult = this.state.confirmationResult;
      const otp = this.state.otp;

      if (confirmationResult) {
        confirmationResult
          .confirm(otp)
          .then((result) => {
            console.log("OTP confirmed. Result:", result);
            this.setState({ isOtpConfirmed: true });
            const fullPhoneNumber =
              this.state.countryCode + this.state.phoneNumber;

            // Save phone number in localStorage
            localStorage.setItem("phoneNumber", fullPhoneNumber);

            console.log("Signing up user");
            const data = {
              authtoken: localStorage.getItem("idToken"),
              name: this.state.name,
              email: this.state.email,
              phoneNumber: fullPhoneNumber,
              organization: null,
              occupation: null,
              limit: null,
            };

            fetch(
              // "https://ytsummarizer-production-5ff2.up.railway.app/signup",
              "https://d10kmmydm845tw.cloudfront.net/signup",
              {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
              .then((response) => response.text())
              .then((data) => {
                console.log(
                  "Received response from server after signup:",
                  data
                );
                if (data === "signed up") {
                  console.log("Signed up, redirecting to dashboard...");
                  localStorage.setItem("isNewUser", "false");
                  route("/dashboard");
                }
              })
              .catch((error) => {
                console.error("Error during signing up:", error);
              });
          })
          .catch((error) => {
            console.error("Error confirming OTP:", error);
          });
      } else {
        console.error("Confirmation result is undefined");
      }
    } else {
      console.log("Signing up user");
      const fullPhoneNumber = this.state.countryCode + this.state.phoneNumber;
      const data = {
        authtoken: localStorage.getItem("idToken"),
        name: this.state.name,
        email: this.state.email,
        phoneNumber: fullPhoneNumber,
        organization: null,
        occupation: null,
        limit: null,
      };

      fetch("https://ytsummarizer-production-5ff2.up.railway.app/signup", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.text())
        .then((data) => {
          console.log("Received response from server after signup:", data);
          if (data === "signed up") {
            console.log("Signed up, redirecting to dashboard...");
            localStorage.setItem("isNewUser", "false");
            route("/dashboard");
          }
        })
        .catch((error) => {
          console.error("Error during signing up:", error);
        });
    }
  };

  handleReferralCodeSubmit = () => {
    console.log("Referral code submitted");

    const data = {
      authtoken: localStorage.getItem("idToken"),
      name: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
      refid: this.state.referralCode,
    };

    fetch("https://d10kmmydm845tw.cloudfront.net/ref", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((data) => {
        if (data === "new user added") {
          console.log("New user added, redirecting to dashboard...");
          localStorage.setItem("isNewUser", "false");
          route("/dashboard");
        } else {
          alert("Invalid reference id.");
        }
      })
      .catch((error) => {
        console.error("Error submitting referral code:", error);
      });
  };
  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleResendOTP = () => {
    console.log("Resend OTP initiated");
    const fullPhoneNumber = this.state.countryCode + this.state.phoneNumber;
    resendOTP(fullPhoneNumber).then((result) => {
      console.log("OTP resent. Result:", result);
      this.setState({ confirmationResult: result, isOtpSent: true });
    });
  };

  render() {
    const useWindowWidth = (breakpoint) => {
      const [isScreenSmall, setIsScreenSmall] = useState(false);

      useEffect(() => {
        const checkSize = () =>
          setIsScreenSmall(window.innerWidth < breakpoint);

        window.addEventListener("resize", checkSize);
        checkSize();

        return () => window.removeEventListener("resize", checkSize);
      }, [breakpoint]);

      return isScreenSmall;
    };

    const isScreenSmall = useWindowWidth(500);

    return (
      <div className={style.background}>
        <div id="recaptcha-container"></div>

        <div className="min-h-screen flex items-center justify-center px-4 py-1 sm:px-6 lg:px-8">
          <div className="max-w-lg w-full bg-white p-8 shadow-lg rounded-lg">
            <div className="text-center">
              <img
                src="../../assets/logo2.png"
                alt="Sentora Logo"
                className="mx-auto h-36 w-auto"
              />
              <h1
                className="mt-6 text-5xl font-medium pb-8 tracking-tight inline-block"
                style={{
                  background:
                    "linear-gradient(180deg, #051367 0%, #2D31FA 113.38%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Sentora
              </h1>
              <h3 className="mt-2 text-2xl tracking-tight text-gray-900">
                Document Your Learnings
              </h3>

              <div className="mt-4 flex justify-center">
                {this.state.isNewUser ? (
                  this.state.isOtpSent ? (
                    <>
                      <div className="flex flex-col">
                        <div>
                          <input
                            type="text"
                            placeholder="Enter OTP"
                            name="otp"
                            value={this.state.otp}
                            onChange={this.handleInputChange}
                          />
                          <button
                            type="button"
                            className={style.google}
                            onClick={this.handleSignInWithPhoneNumber}
                          >
                            Verify OTP
                          </button>
                        </div>

                        <div
                          onClick={this.handleResendOTP}
                          className="text-sm text-blue-700 hover:text-gray-600 hover:underline`"
                        >
                          Resend OTP
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-col">
                        <div>
                          <select
                            value={this.state.countryCode}
                            onChange={this.handleCountryChange}
                            className="w-12 md:w-24"
                          >
                            {countries.map((country) => (
                              <option value={country.dial_code}>
                                {isScreenSmall
                                  ? country.dial_code
                                  : `${country.name} (${country.dial_code})`}
                              </option>
                            ))}
                          </select>

                          <input
                            type="text"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            value={this.state.phoneNumber}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <button
                          type="button"
                          className={style.google}
                          onClick={this.handleSignInWithPhoneNumber}
                        >
                          Send OTP
                        </button>
                      </div>
                    </>
                  )
                ) : this.state.referralSignIn ? (
                  <div>
                    <input
                      type="text"
                      placeholder="Referral Code"
                      name="referralCode"
                      value={this.state.referralCode}
                      onChange={this.handleInputChange}
                    />
                    <button
                      onClick={this.handleReferralCodeSubmit}
                      className={style.google}
                    >
                      Submit
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center flex-col">
                    <button
                      type="button"
                      className={style.google}
                      onClick={this.handleSignInWithGoogle}
                      style={{
                        display:
                          this.state.isApproved === false ? "none" : "block",
                      }}
                    >
                      Sign in with Google
                    </button>
                    <button
                      type="button"
                      className="text-blue-800 text-md hover:text-gray-500 hover:text-underline pt-6"
                      onClick={this.handleSignInWithGoogleReferral}
                    >
                      New User With Referral? Click Here
                    </button>
                  </div>
                )}

                {/* ) 
              ) : (
               <div>
               <button
                //       type="button"
                //       className={style.google}
                //       onClick={this.handleSignInWithGoogle}
                //       style={{
                //         display:
                //           this.state.isApproved === false ? "none" : "block",
                //       }}
                //     >
                //       Sign in with Google
                //     </button>
                //     <button
                //       type="button"
                //       className={style.google}
                //       onClick={this.handleSignInWithGoogleReferral}
                //     >
                //       New User With Referral Click Here
                //     </button>
                //   </div>
                // )} */}

                {this.state.isApproved === false && (
                  <div>
                    <div
                      className="text-xl text-gray-600 font-bold"
                      style={{ padding: "10px", textAlign: "center" }}
                    >
                      You are not a verified user.
                    </div>
                    <a href="https://forms.gle/Fo1qb5jUVqmQNcC46">
                      <button className={style.google}>Join Waitlist</button>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
