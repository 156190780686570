// import { initializeApp } from "firebase/app";
// import {
//   getAuth,
//   signInWithPhoneNumber,
//   RecaptchaVerifier,
// } from "firebase/auth";

// // env the variables
// const firebaseConfig = {
//   apiKey: "AIzaSyDmjRpQMWWdSpeI8i0myydV-g5JRG2vm3o",
//   authDomain: "yt-summarizer-ai.firebaseapp.com",
//   projectId: "yt-summarizer-ai",
//   storageBucket: "yt-summarizer-ai.appspot.com",
//   messagingSenderId: "810359999452",
//   appId: "1:810359999452:web:c894f455cde399e71fdd19",
//   measurementId: "G-M3Z45FNNG5",
// };

// initializeApp(firebaseConfig);
// const auth = getAuth();

// export const signInWithPhoneNumberOTP = (phoneNumber) => {
//   const appVerifier = new RecaptchaVerifier(
//     "recaptcha-container",
//     { size: "invisible" },
//     auth
//   );
//   return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
//     .then((confirmationResult) => {
//       // SMS sent.
//       return confirmationResult;
//     })
//     .catch((error) => {
//       // Error; SMS not sent
//       console.log(error);
//     });
// };

import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

// env the variables
const firebaseConfig = {
  apiKey: "AIzaSyDmjRpQMWWdSpeI8i0myydV-g5JRG2vm3o",
  authDomain: "yt-summarizer-ai.firebaseapp.com",
  projectId: "yt-summarizer-ai",
  storageBucket: "yt-summarizer-ai.appspot.com",
  messagingSenderId: "810359999452",
  appId: "1:810359999452:web:c894f455cde399e71fdd19",
  measurementId: "G-M3Z45FNNG5",
};

initializeApp(firebaseConfig);
const auth = getAuth();
const provider = new GoogleAuthProvider();

export const checkIfUserIsNew = (idToken) => {
  return fetch("https://ytsummarizer-production-5ff2.up.railway.app/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ idToken }),
  })
    .then((res) => res.json())
    .then((data) => data.isNewUser)
    .catch((error) => console.error("Error checking if user is new:", error));
};

export const signInWithGoogle = () => {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const name = result.user.displayName;
        const email = result.user.email;
        const profilePic = result.user.photoURL;
        const idToken = result.user.uid;

        localStorage.setItem("name", name);
        localStorage.setItem("email", email);
        localStorage.setItem("profilePic", profilePic);
        localStorage.setItem("idToken", idToken);

        // Check if the user is new or existing.
        const isNewUser = await checkIfUserIsNew(idToken);
        localStorage.setItem("isNewUser", isNewUser ? "true" : "false");

        resolve({
          isNewUser,
          name,
          email,
          profilePic,
          idToken,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

let appVerifier;

function resetRecaptcha() {
  return new Promise((resolve, reject) => {
    if (appVerifier) {
      appVerifier.clear();
      appVerifier = null;
      resolve();
    } else {
      resolve();
    }
  });
}

export const signInWithPhoneNumberOTP = async (phoneNumber) => {
  const recaptchaContainerId = "recaptcha-container-" + new Date().getTime();
  const newRecaptchaContainer = document.createElement("div");
  newRecaptchaContainer.id = recaptchaContainerId;
  document.body.appendChild(newRecaptchaContainer);

  const appVerifier = new RecaptchaVerifier(
    recaptchaContainerId,
    { size: "invisible" },
    auth
  );

  return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmationResult) => {
      return confirmationResult;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const resendOTP = async (phoneNumber) => {
  const recaptchaContainerId = "recaptcha-container-" + new Date().getTime();
  const newRecaptchaContainer = document.createElement("div");
  newRecaptchaContainer.id = recaptchaContainerId;
  document.body.appendChild(newRecaptchaContainer);

  const appVerifier = new RecaptchaVerifier(
    recaptchaContainerId,
    { size: "invisible" },
    auth
  );

  return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmationResult) => {
      return confirmationResult;
    })
    .catch((error) => {
      console.log(error);
    });
};

// export const signInWithPhoneNumberOTP = (phoneNumber) => {
//   // Call the reset function before rendering a new RecaptchaVerifier instance
//   resetRecaptcha();

//   const appVerifier = new RecaptchaVerifier(
//     "recaptcha-container",
//     { size: "invisible" },
//     auth
//   );

//   return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
//     .then((confirmationResult) => {
//       // SMS sent.
//       return confirmationResult;
//     })
//     .catch((error) => {
//       // Error; SMS not sent
//       console.log(error);
//     });
// };

// export function resetRecaptcha() {
//   const recaptchaContainer = document.getElementById("recaptcha-container");

//   // Ensure that the recaptchaContainer is not null
//   if (recaptchaContainer) {
//     while (recaptchaContainer.firstChild) {
//       recaptchaContainer.removeChild(recaptchaContainer.firstChild);
//     }
//   }
// }

// export const resendOTP = (phoneNumber) => {
//   resetRecaptcha();
//   const appVerifier = new RecaptchaVerifier(
//     "recaptcha-container",
//     { size: "invisible" },
//     auth
//   );
//   return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
//     .then((confirmationResult) => {
//       return confirmationResult;
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

// export const signInWithPhoneNumberOTP = (phoneNumber) => {
//   const appVerifier = new RecaptchaVerifier(
//     "recaptcha-container",
//     { size: "invisible" },
//     auth
//   );
//   return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
//     .then((confirmationResult) => {
//       // SMS sent.
//       return confirmationResult;
//     })
//     .catch((error) => {
//       // Error; SMS not sent
//       console.log(error);
//     });
// };
