import { Link } from "preact-router/match";
import style from "./dashboard.css";
import { useState, useEffect, useCallback, useContext } from "preact/hooks";
import { route } from "preact-router";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/footer/footer";
import { UrlContext } from "../context/UrlContext";
// import { ThemeContext } from "../context/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import { useRef } from "preact/hooks";

export default function Dashboard(props) {
  // const { theme, switchTheme } = useContext(ThemeContext);
  const { rl, setRl } = useContext(UrlContext);

  const [posts, setPosts] = useState([
    { authtoken: "", imagesrc: "", tags: "", title: "", videourl: "" },
  ]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isLoadingSummary, setIsLoadingSummary] = useState(false);

  const { videoUrl, setVideoUrl } = props;
  const [iurl, setIurl] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  const profilepicture = localStorage.getItem("profilePic");

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const navigate = useCallback((url) => {
    route(url);
  }, []);

  const [currentVideo, setCurrentVideo] = useState({
    name: "",
    channelName: "",
    image: "",
  });
  const [url, setUrl] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [loading, setLoading] = useState(true);
  const [videourl, setVideourl] = useState("");
  const [urlParam, setUrlParam] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const urlParamFromSearch = searchParams.get("url");
    if (urlParamFromSearch) {
      const decodedUrl = decodeURIComponent(urlParamFromSearch);
      console.log(decodedUrl);
      setUrlParam(decodedUrl);
      handleSubmit(decodedUrl);
    }
  }, []);

  const authToken = localStorage.getItem("idToken");
  const [postCount, setPostCount] = useState(0);

  const fetchPosts = useCallback(async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ authtoken: authToken }),
    };

    try {
      const response = await fetch(
        // "https://ytsummary-dev.ap-southeast-1.elasticbeanstalk.com/dashboard",
        // "https://ytsummarizer-production-5ff2.up.railway.app/dashboard",
        "https://d10kmmydm845tw.cloudfront.net/dashboard",
        requestOptions
      );
      const data = await response.json();

      // Extract video URLs and set the state
      const urls = data.map((post) => post.videourl);
      setVideoUrls(urls);

      setPosts(data);
      setFilteredPosts(data);
      setLoading(false);

      console.log(data.length);

      let postCount = data.length;
      setRemaining(100 - data.length);
      console.log(remaining);

      // let count;
      // count = data.length;
      // setPostCount(count);
      // console.log(postCount);

      if (data.length > 0) {
        const lastPost = data[data.length - 1];
        setCurrentVideo({
          name: lastPost.title,
          channelName: "Channel Name", // Replace with the actual channel name
          imageSrc: lastPost.imagesrc,
        });
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  }, [authToken]);

  useEffect(() => {
    if (!authToken) {
      navigate("/login");
    }
  }, [authToken, navigate]);

  useEffect(() => {
    fetchPosts();
  }, [authToken, fetchPosts]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchPosts();
    }, 100000);
    return () => clearInterval(intervalId);
  }, [fetchPosts]);

  function fetchSummary(videourl) {
    const encodedUrl = encodeURIComponent(videourl);
    console.log(videourl);

    route(`/summary/${encodedUrl}`);
  }

  useEffect(() => {
    const filtered = posts.filter(
      (post) =>
        post.title &&
        post.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPosts(filtered);
  }, [searchTerm, posts]);

  const handleSearchChange = (e) => {
    const value = e.target.value || ""; // default to empty string if value is null
    setSearchTerm(value.toLowerCase()); // call toLowerCase() after checking for null
  };

  const handleSignOut = () => {
    // Clear authentication tokens or user data, e.g., localStorage or cookies
    localStorage.removeItem("authToken");
    // Redirect to the login page or any other appropriate page
    route("/login");
  };

  // Inside the Dashboard component function:
  useEffect(() => {
    if (rl) {
      // setUrl();
      setShowModal2(true);
      handleAutoSubmit(rl);
      setRl(false); // Clear the URL after using it
    }
  }, [rl, setRl, handleAutoSubmit]);

  // const inputRef = useRef(null);

  let desktopInputRef = useRef(null);
  let mobileInputRef = useRef(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [summaryDuplicate, setSummaryDuplicate] = useState(false);
  const [summaryduplicateurl, setSummaryduplicateurl] = useState("");

  const validateYouTubeUrl = (url) => {
    const pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
    return pattern.test(url);
  };
  const [isValid, setIsValid] = useState(true);

  const [inputError, setInputError] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleChange = (e) => {
    const url = e.target.value;
    setUrl(url);
    setIsValid(validateYouTubeUrl(url));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (!validateYouTubeUrl(url)) {
      setInputError(true);
      setShowTooltip(true);

      setTimeout(() => {
        setInputError(false);
        setShowTooltip(false);
      }, 3000);
      return;
    }

    if (remaining === 0) {
      setIsModalVisible(true);
      return;
    }

    let a = url;
    setSummaryduplicateurl(a);

    // Disable the button
    setButtonDisabled(true);

    // Check if the URL is already summarized
    if (videoUrls.includes(url)) {
      // alert("The summary is already present");
      setSummaryDuplicate(true);
      setButtonDisabled(false);

      return;
    }

    setIsLoadingSummary(true); // Start loading summary

    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      console.log(formattedDate);

      const response = await fetch(
        // "https://ytsummarizer-production-5ff2.up.railway.app/summarize",
        // "https://ytsummary-dev.ap-southeast-1.elasticbeanstalk.com/summarize",
        "https://d10kmmydm845tw.cloudfront.net/summarize",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            videoUrl: url,
            date: formattedDate,
            authtoken: authToken,
          }),
        }
      );

      const data = await response.json();

      // Add the new URL to the state
      // ... same code as before ...

      // Add the new URL to the state
      setVideoUrls([...videoUrls, url]);

      fetchPosts();

      setSuccessMessage("Summary submitted successfully!");
      setIsLoadingSummary(false); // Finish loading summary
    } catch (error) {
      console.error(error);
      console.error("Error submitting summary:", error);
      setIsLoadingSummary(false); // Finish loading summary
    } finally {
      // Always re-enable the button, whether the fetch succeeded or failed
      setButtonDisabled(false);
    }
  }

  // async function handleSubmit(e) {
  //   e.preventDefault();

  //   // Check if the URL is already summarized
  //   if (videoUrls.includes(url)) {
  //     alert("The summary is already present");
  //     return;
  //   }

  //   setIsLoadingSummary(true); // Start loading summary

  //   try {
  //     const currentDate = new Date();
  //     const formattedDate = currentDate.toLocaleDateString("en-US", {
  //       year: "numeric",
  //       month: "short",
  //       day: "numeric",
  //     });

  //     console.log(formattedDate);

  //     const response = await fetch(
  //       // "https://ytsummarizer-production-5ff2.up.railway.app/summarize",
  //       // "https://ytsummary-dev.ap-southeast-1.elasticbeanstalk.com/summarize",
  //       "https://d10kmmydm845tw.cloudfront.net/summarize",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           videoUrl: url,
  //           date: formattedDate,
  //           authtoken: authToken,
  //         }),
  //       }
  //     );

  //     const data = await response.json();

  //     alert("Your Summary will be shown on the Dashboard");

  //     // Add the new URL to the state
  //     setVideoUrls([...videoUrls, url]);

  //     fetchPosts();

  //     setSuccessMessage("Summary submitted successfully!");
  //     setIsLoadingSummary(false); // Finish loading summary
  //   } catch (error) {
  //     console.error(error);
  //     console.error("Error submitting summary:", error);
  //     setIsLoadingSummary(false); // Finish loading summary
  //   }
  // }

  async function handleAutoSubmit(url) {
    console.log("URL: ", url);
    // Check the value of url

    // Check if the URL is already summarized
    if (videoUrls.includes(url)) {
      alert("The summary is already present");
      return;
    }

    // setIsLoadingSummary(true); // Start loading summary

    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      const response = await fetch(
        // "https://ytsummarizer-production-5ff2.up.railway.app/summarize",
        "https://d10kmmydm845tw.cloudfront.net/summarize",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            videoUrl: url,
            date: formattedDate,
            authtoken: authToken,
          }),
        }
      );
      showModalFor3Seconds();
      console.log("123");
      // Add the new URL

      // const encodedUrl = encodeURIComponent(url);
      // console.log("Encoded URL: ", encodedUrl); // Check the value of encoded URL
      // fetchSummary(pagevideo);
      // setSuccessMessage("Summary submitted successfully!");
      // setIsLoadingSummary(false); // Finish loading summary
    } catch (error) {
      console.error(error);
      console.error("Error submitting summary:", error);
      // setIsLoadingSummary(false); // Finish loading summary
    }
  }
  const showModalFor3Seconds = () => {
    setTimeout(async () => {
      try {
        await fetchPosts();
        setShowModal2(false);
      } catch (err) {
        console.error(err);
      }
    }, 3000);
  };

  async function submitVideoUrl(videoUrl) {
    setIsLoadingSummary(true); // Start loading summary

    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      const response = await fetch(
        // "http://ytsummary-dev.ap-southeast-1.elasticbeanstalk.com/summarize",
        // "https://ytsummarizer-production-5ff2.up.railway.app/summarize",
        "https://d10kmmydm845tw.cloudfront.net/summarize",

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            videoUrl: videoUrl,
            date: formattedDate,
            authtoken: authToken,
          }),
        }
      );
      const data = await response.json();

      fetchPosts();

      setSuccessMessage("Summary submitted successfully!");
      setIsLoadingSummary(false); // Finish loading summary

      // Add this line to navigate to the summary page with the encoded URL
      const encodedUrl = encodeURIComponent(videoUrl);
      route(`/summary/${encodedUrl}`);
    } catch (error) {
      console.error(error);
      console.error("Error submitting summary:", error);
      setIsLoadingSummary(false); // Finish loading summary
    }
  }

  // async function submitVideoUrl(videoUrl) {
  //   setIsLoadingSummary(true); // Start loading summary

  //   try {
  //     const currentDate = new Date();
  //     const formattedDate = currentDate.toLocaleDateString("en-US", {
  //       year: "numeric",
  //       month: "short",
  //       day: "numeric",
  //     });

  //     const response = await fetch(
  //       // "https://ytsummary-dev.ap-southeast-1.elasticbeanstalk.com/summarize",
  //       "https://ytsummarizer-production-5ff2.up.railway.app/summarize",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           // Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //         body: JSON.stringify({
  //           videoUrl: videoUrl,
  //           date: formattedDate,
  //           authtoken: authToken,
  //         }),
  //       }
  //     );

  //     console.log(response); // Add this li

  //     if (response.headers.get("content-type")?.includes("application/json")) {
  //       const data = await response.json();

  //       fetchPosts();

  //       setSuccessMessage("Summary submitted successfully!");
  //       setIsLoadingSummary(false); // Finish loading summary

  //       // Pass the videoUrl directly to fetchSummary
  //       fetchSummary(videoUrl);
  //     } else {
  //       throw new Error("Invalid response format");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     console.error("Error submitting summary:", error);
  //     setIsLoadingSummary(false); // Finish loading summary
  //   }
  // }

  useEffect(() => {
    if (videoUrl) {
      console.log(videoUrl);
      submitVideoUrl(videoUrl);
      setVideoUrl(""); // Reset the video URL
    }
  }, [videoUrl]);

  const loadingBarRef = useRef();

  useEffect(() => {
    if (isLoadingSummary) {
      loadingBarRef.current.style.width = "100%";
      loadingBarRef.current.style.transition = "width 5s linear";

      setTimeout(() => {
        setIsLoadingSummary(false);
        fetchSummary(url);
      }, 4000);
    } else {
      loadingBarRef.current.style.width = "0%";
      loadingBarRef.current.style.transition = "none";
    }
  }, [isLoadingSummary]);

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  return (
    <div className="flex flex-col min-h-screen">
      {/* <Navbar></Navbar> */}
      <div className="bg-white">
        <div className={style.header}>
          <div className="flex  w-full pt-6">
            <div className="font-medium h-4 w-auto text-slate-50">
              <Link href="/dashboard">
                <img
                  src="../../assets/logo.png"
                  alt="Sentora Logo"
                  className="w-44 ml-8 hidden md:block"
                />
                <img
                  src="../../assets/logo3.png"
                  alt="Sentora Logo"
                  className="w-8 mx-4 visible sm:hidden"
                />
              </Link>
            </div>

            <div className="flex-grow mx-6">
              <div className="md:block hidden ">
                <div className={style.input}>
                  <div className="relative m-1.5 rounded-md shadow-sm flex   ">
                    <div className="absolute inset-y-0  left-0 pl-3 flex items-center pointer-events-none   ">
                      <FontAwesomeIcon icon={faLink} className="text-white" />
                    </div>
                    <input
                      ref={desktopInputRef}
                      type="text"
                      placeholder={
                        inputError
                          ? "Invalid YouTube URL!"
                          : "Paste link to YouTube video here"
                      }
                      className={
                        inputError
                          ? "w-full bg-[#131524] text-red-500 rounded-md sm:py-2 hidden md:block py-1 mr-1 pl-10 pr-20"
                          : "w-full bg-[#131524] text-white rounded-md sm:py-2 hidden md:block py-1 mr-1 pl-10 pr-20"
                      }
                      onChange={(e) => {
                        setUrl(e.target.value);
                        setInputError(false); // Reset the error when user types in the input field
                      }}
                    />

                    <button
                      className={style.button}
                      onClick={handleSubmit}
                      disabled={!isValid || !url || isButtonDisabled}
                    >
                      Summarize
                    </button>
                  </div>
                </div>
                {showTooltip && (
                  <div className="relative bottom-full mb-2 p-2 rounded text-red-500 text-xs">
                    Invalid YouTube URL!
                  </div>
                )}
              </div>

              {/* //new */}

              <div>
                <div>
                  <button
                    onClick={() => setShowModal(true)}
                    disabled={remaining === 0}
                    className="w-full text-xl text-white rounded-md block md:hidden py-2"
                  >
                    Create Summary
                  </button>
                </div>

                {summaryDuplicate && (
                  <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg w-2/3 md:w-1/3 h-40vh p-6 shadow-lg">
                      <div className="flex justify-between text-center items-start">
                        <p className="text-xl">
                          The Summary is already Present on your Dashboard
                        </p>
                        <button
                          type="button"
                          onClick={() => setSummaryDuplicate(false)}
                          className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                        >
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </button>
                      </div>

                      <div className="mt-4 flex justify-center">
                        <button
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                          onClick={() => fetchSummary(summaryduplicateurl)}
                        >
                          View Summary
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {/* 

                {summaryDuplicate && (
                  <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg  w-2/3 md:w-1/3 h-40vh p-6 shadow-lg">
                      <div className="flex justify-end"></div>

                      <div className="mt-2">
                        <div className="relative top-[4rem] right-0 pr-2">
                          <button
                            type="button"
                            onClick={() => setSummaryDuplicate(false)}
                            className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                          >
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              ></path>
                            </svg>
                          </button>
                        </div>
                        <p className="text-xl text-center">
                          The Summary is already Present on your Dashboard
                        </p>
                      </div>
                      <div className="mt-4 flex justify-center">
                        <button
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                          onClick={() => fetchSummary(summaryduplicateurl)}
                        >
                          View Summary
                        </button>
                      </div>
                    </div>
                  </div>
                )} */}

                {isModalVisible && (
                  <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg  w-2/3 md:w-1/3 h-40vh p-6 shadow-lg">
                      <div className="flex justify-end"></div>

                      <div className="mt-2">
                        <p className="text-xl text-center">
                          Summary limit reached. Please try again later.
                        </p>
                      </div>
                      <div className="mt-4 flex justify-center">
                        <button
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                          onClick={() => setIsModalVisible(false)}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {showModal && (
                  <div className={style.modal2}>
                    <div
                      className={style.modalOverlay}
                      onClick={() => setShowModal(false)}
                      style={{ pointerEvents: showModal ? "auto" : "none" }}
                    ></div>

                    <div className="fixed z-10 inset-0  overflow-y-auto">
                      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div
                          className="fixed inset-0 transition-opacity"
                          onClick={() => setShowModal(false)}
                        >
                          <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
                        </div>

                        <div
                          className={style.modalContent}
                          role="dialog"
                          aria-modal="true"
                          aria-labelledby="modal-headline"
                        >
                          <div
                            className="fixed inset-x-0 bottom-0 py-16 bg-white rounded-t-3xl px-4   text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-sm w-full"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                          >
                            <div className="absolute top-0 right-0 pt-4 pr-4">
                              <button
                                type="button"
                                onClick={() => setShowModal(false)}
                                className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                              >
                                <svg
                                  className="h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                            <div>
                              <div className=" text-center  font-medium pb-6 text-xl">
                                Create Summary
                              </div>

                              <input
                                type="text"
                                placeholder="Paste link to YouTube video here "
                                className="w-full bg-white border-blue-800 border-2 py-2 text-black rounded-md sm:py-2 pl-10 pr-20"
                                onChange={(e) => setUrl(e.target.value)}
                              />
                            </div>{" "}
                            <div>
                              {" "}
                              {showTooltip && (
                                <div className=" z-[10000]  p-2 rounded text-red-500 text-xs">
                                  Invalid YouTube URL!
                                </div>
                              )}{" "}
                            </div>
                            <div className="mt-5 sm:mt-6">
                              <span className="flex w-full rounded-md shadow-sm">
                                <button
                                  onClick={handleSubmit}
                                  type="button"
                                  className="inline-flex justify-center w-full  rounded-md px-4 py-4 bg-blue-600 text-medium text-lg leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                >
                                  Summarize
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* search ends */}
            </div>

            <div>
              {" "}
              {showModal2 && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                  <div className="bg-white rounded-lg  w-2/3 md:w-1/3 h-40vh p-6 shadow-lg">
                    <div className="flex justify-end"></div>
                    <div className="mt-2">
                      <p className="text-xl text-center">
                        Your summary will be shown on the dashboard
                      </p>
                    </div>
                    <div className="mt-4 flex justify-center">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => setShowModal2(false)}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {/* {showModal2 && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                  <div className="bg-white rounded-lg w-1/2 h-40vh p-6 shadow-lg">
                    <div className="flex justify-end"></div>
                    <div className="mt-2">
                      <p className="text-xl text-center">
                        Your summary will be shown on the dashboard
                      </p>
                    </div>
                  </div>
                </div>
              )} */}
            </div>

            <div className="text-white flex items-center">
              {/* <button onClick={route("/user")}>
                Referral
  
              </button> */}
              <div class="hidden lg:flex items-center border-2 border-white mx-2 px-3 py-1 rounded-lg text-white">
                <span>{remaining}</span> <span>&nbsp;credits</span>
              </div>
              <div>
                <a href="/user" className="hover:text-indigo-600">
                  Refferals
                </a>
              </div>
              <div class="relative">
                <img
                  class="h-10 w-10 mx-2 rounded-full cursor-pointer"
                  src={profilepicture}
                  alt=""
                  onClick={() => setShowMenu(!showMenu)}
                />
                {showMenu && (
                  <div class="absolute top-14 right-4 w-36 z-10 bg-white text-black p-2 rounded-lg shadow-lg">
                    <ul className="font-medium p-3">
                      <li>
                        <a href="/user" className="hover:text-indigo-600">
                          Profile
                        </a>
                      </li>
                      <li>
                        <button
                          onClick={handleSignOut}
                          className="hover:text-indigo-600"
                        >
                          Sign Out
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="border-b-2">
          {/* <div className="ml-[5rem] mr-[2rem] max-w-7xl px-6 mt-6 pb-4 lg:px-8 "> */}
          <div
            className="  px-12
           mt-2 md:mt-6 pb-4 lg:px-12 "
          >
            <div className="  mt-4  flex   justify center md:justify-between ">
              <div className="pb-2">
                <h2 className="hidden md:block  ml-[5rem] mr-[2rem] text-1xl font-medium tracking-tight text-[#061468] sm:text-4xl">
                  Dashboard
                </h2>

                {/* <SelectMenu></SelectMenu> */}
              </div>
              <div className="flex">
                <div className="mr-[4rem]">
                  <div className="">
                    {/* search begins */}
                    <div>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <FontAwesomeIcon
                            icon={faSearch}
                            className="text-gray-400"
                          />
                        </div>
                        <input
                          type="text"
                          name="price"
                          id="price"
                          className="block w-full rounded-md py-1.5 pl-10 pr-24 text-black bg-white border-gray-400 border-[1px] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Search"
                          value={searchTerm}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </div>

                    {/* search ends */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid mx-8 max-w-2xl text-gray-600 grid-cols-1 gap-x-8 sm:gap-y-16  gap-y-12 pt-8 sm:pt-12 lg:mx-28 lg:max-w-none lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6">
          <button
            onClick={() => {
              if (window.matchMedia("(min-width: 768px)").matches) {
                desktopInputRef.current.focus();
              } else {
                mobileInputRef.current.focus();
              }
            }}
            className="md:block hidden"
          >
            <div
              className="relative rounded-md h-full overflow-hidden shadow-lg border-dashed border-2 border-gray-300"
              onClick={() => inputRef.current.focus()}
            >
              <div className="p-4 flex flex-col items-center justify-center h-full">
                <div className="text-6xl  font-light text-gray-600   mb-2">
                  +
                </div>
                <h3 className="text-lg font-medium text-gray-700 mb-8">
                  New Summary
                </h3>
              </div>
            </div>
          </button>

          <button
            onClick={() => {
              setShowModal(true);
            }}
            className="block md:hidden"
          >
            <div
              className="relative rounded-md h-full overflow-hidden shadow-lg border-dashed border-2 border-gray-300"
              onClick={() => inputRef.current.focus()}
            >
              <div className="p-4 flex flex-col items-center justify-center h-full">
                <div className="text-6xl  font-light text-gray-600   mb-2">
                  +
                </div>
                <h3 className="text-lg font-medium text-gray-700 mb-8">
                  New Summary
                </h3>
              </div>
            </div>
          </button>

          {!loading &&
            Array.isArray(filteredPosts) &&
            filteredPosts
              .slice()
              .reverse()
              .map((post) => (
                <div>
                  <article
                    key={post.videourl}
                    className="relative rounded-md  border-[1px] overflow-hidden shadow-lg"
                    style={{ cursor: "pointer" }}
                    onClick={() => fetchSummary(post.videourl)}
                  >
                    <div className="relative h-48s md:h-64 bg-white m-4 ">
                      <img
                        src={post.imagesrc}
                        alt={post.title}
                        className="h-[140px]  text-center text-lg w-full object-cover text-gray-200 bg-[#061468] rounded-md transition-all duration-500 ease-in-out"
                      />
                      <div className="absolute inset-0 opacity-25 transition-all duration-500 ease-in-out"></div>
                      <h4 className="text-sm  pt-4 font-normal text-gray-700">
                        {/* {post.title} */} {post.date}
                      </h4>
                      <h3
                        className="text-md font-medium pt-1 text-gray-900 mb-2"
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 4, // Adjust the number of lines you want to display
                          maxHeight: "2.5rem", // Adjust the maximum height according to your preference
                          textOverflow: "ellipsis",
                          lineHeight: "1.5rem", // Adjust line height if needed
                          wordWrap: "break-word",
                          minHeight: "2.5rem", // Set a minimum height to avoid box resizing
                        }}
                      >
                        {post.title}
                      </h3>
                    </div>
                    <div className="flex justify-end">
                      {/* <div className="p-4 font-medium">Channel Name</div> */}
                      <div className="p-4">
                        {/* <button
                          className="text-gray-700 hover:text-gray-800"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              summaryData.videosummary
                            );
                            alert("Summary copied to clipboard!");
                          }}
                        >
                          <span className="">
                            {" "}
                            <img
                              src="../../assets/copy.png"
                              alt="Sentora Logo"
                             
                              className="  w-10"
                            />
                          </span>
                        </button> */}
                      </div>
                    </div>

                    <div className="absolute inset-0 z-10 border-2 border-transparent hover:border-gray-200 transition-all duration-500 ease-in-out">
                      <span className="sr-only">{post.title}</span>
                    </div>
                  </article>
                </div>
              ))}
          {isLoadingSummary && (
            <div className="fixed bottom-0 left-0 w-full bg-white border-t border-gray-300 py-3">
              <div className="relative w-full h-1 bg-gray-200">
                <div
                  ref={loadingBarRef}
                  className="absolute top-0 left-0 h-1 bg-indigo-600"
                ></div>
              </div>
              <div className="container mx-auto px-4 flex justify-between items-center">
                <div className="flex items-center">
                  <div>
                    <div className="font-medium  text-xl m-8 text-gray-900">
                      Your summary is being prepared...
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-indigo-600 font-medium">
                    Fetching Summary
                  </div>
                  <div
                    class="inline-block text-indigo-600 m-2 h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div></div>
      <div className="mt-auto">
        <Footer></Footer>
      </div>
    </div>
  );
}
