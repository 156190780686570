import { h } from "preact";
import { route } from "preact-router";
// // import style from "./login.css";
// import { signInWithGoogle } from "../../../Firebase";

const Error = () => {
  return (
    <div>
      <div className="h-screen w-full flex items-center justify-center bg-blue-800">
        <div className="bg-white rounded-lg shadow p-8 m-4 max-w-xs max-h-full text-center">
          <div className="mb-4">
            <h1 className="font-bold text-black text-5xl">404</h1>
            <p className="text-blue-700">Oops! Page not found.</p>
          </div>
          <p className="mb-8 text-gray-700">
            The page you are looking for doesn't exist or has been moved.
          </p>
          <div className="mb-4">
            <button
              className="py-2 px-4 bg-blue-800 text-white font-semibold rounded-lg shadow-md hover:bg-yellow-700 focus:outline-none"
              onClick={() => window.history.back()}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
