import { h } from "preact";
import { route } from "preact-router";
import { Link } from "preact-router";

import { useState } from "preact/hooks";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit email to server or perform other actions
  };

  return (
    <div>
      <footer class="bg-white rounded-lg  m-4">
        <hr class="my-6 border-gray-200 sm:mx-auto  lg:mt-8" />
        <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <div class="sm:flex sm:items-center sm:justify-between">
            <div className="flex mb-4 md:mb-0">
              <a href="https://sentora.ai/" class="flex items-center  sm:mb-0">
                <img
                  src="../../assets/logo2.png"
                  class="h-8 mr-3"
                  alt="Flowbite Logo"
                />
                <span class="self-center text-2xl text-gray-800 font-semibold whitespace-nowrap ">
                  Sentora
                </span>
              </a>
              <span class="self-center text-2xl text-gray-800 mx-4 font-semibold whitespace-nowrap ">
                <a href="mailto:ishan@sentora.in">| Contact</a>
              </span>
            </div>
            <ul class="flex flex-wrap items-center mb-6 text-md font-medium text-gray-500 sm:mb-0 ">
              <li>
                <span class="block text-md text-gray-500 sm:text-center ">
                  © 2023{" "}
                  <a href="https://sentora.ai/" class="hover:underline">
                    Sentora
                  </a>
                  . All Rights Reserved.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
